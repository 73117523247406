import React from 'react';

function SupportIcon() {
    return (
        <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 16.2422C24 12.744 21.6429 9.68756 18.319 8.76654C18.11 3.89722 14.0839 0 9.16406 0C4.1109 0 0 4.1109 0 9.16406C0 10.8109 0.438354 12.4144 1.27075 13.8221L0.0336914 18.2943L4.50604 17.0574C5.80005 17.8226 7.25977 18.2536 8.76636 18.3186C9.68719 21.6427 12.7438 24 16.2422 24C17.6385 24 18.9965 23.6281 20.1896 22.9215L23.9661 23.9661L22.9215 20.1896C23.6281 18.9965 24 17.6385 24 16.2422ZM4.72797 15.5369L2.0506 16.2775L2.79126 13.6002L2.62244 13.3361C1.82684 12.0914 1.40625 10.6487 1.40625 9.16406C1.40625 4.88635 4.88635 1.40625 9.16406 1.40625C13.4418 1.40625 16.9219 4.88635 16.9219 9.16406C16.9219 13.4418 13.4418 16.9219 9.16406 16.9219C7.67944 16.9219 6.23694 16.5013 4.992 15.7057L4.72797 15.5369ZM21.9494 21.9494L19.9627 21.3997L19.6974 21.5724C18.6689 22.2405 17.4739 22.5938 16.2422 22.5938C13.4927 22.5938 11.0766 20.816 10.2206 18.2668C14.4309 17.7814 17.7814 14.4309 18.267 10.2204C20.816 11.0766 22.5938 13.4927 22.5938 16.2422C22.5938 17.4739 22.2405 18.6689 21.5724 19.6974L21.3997 19.9627L21.9494 21.9494Z" fill="currentColor"/>
        <path d="M8.46094 12.7031H9.86719V14.1094H8.46094V12.7031Z" fill="currentColor"/>
        <path d="M10.5703 7.03125C10.5703 7.4306 10.4077 7.79919 10.1125 8.06927L8.46094 9.58099V11.2969H9.86719V10.2003L11.062 9.10675C11.6431 8.57483 11.9766 7.81842 11.9766 7.03125C11.9766 5.48035 10.715 4.21875 9.16406 4.21875C7.61316 4.21875 6.35156 5.48035 6.35156 7.03125H7.75781C7.75781 6.2558 8.38861 5.625 9.16406 5.625C9.93951 5.625 10.5703 6.2558 10.5703 7.03125Z" fill="currentColor"/>
        </svg>
    );
}
export default SupportIcon;