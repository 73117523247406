import React from 'react';

function ElmSchoolIcon() {
    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M881 4284 c-18 -23 -21 -41 -21 -120 l0 -94 -160 0 c-211 0 -200 10
-200 -185 l0 -145 -160 0 c-147 0 -162 -2 -180 -20 -20 -20 -20 -33 -20 -1515
0 -1482 0 -1495 20 -1515 20 -20 33 -20 2400 -20 2367 0 2380 0 2400 20 19 19
20 33 20 278 0 289 -3 302 -63 302 -71 0 -77 -20 -77 -259 l0 -201 -2280 0
-2280 0 0 1395 0 1395 110 0 110 0 0 -1254 c0 -1220 1 -1254 19 -1277 l19 -24
2020 0 c1972 0 2020 0 2041 19 l21 19 0 1259 0 1258 110 0 110 0 0 -254 c0
-241 1 -255 21 -280 25 -32 70 -35 99 -6 19 19 20 33 20 330 0 297 -1 311 -20
330 -18 18 -33 20 -180 20 l-160 0 0 145 c0 195 11 185 -199 185 l-159 0 -4
99 c-5 146 3 141 -222 141 -295 0 -535 -36 -773 -115 -233 -78 -469 -212 -631
-361 l-73 -67 -47 45 c-344 327 -833 498 -1429 498 l-182 0 -20 -26z m523
-130 c400 -49 724 -188 999 -429 l87 -76 0 -1214 0 -1215 -52 22 c-249 100
-704 212 -1208 297 l-225 38 -3 1289 c-1 709 0 1295 3 1303 6 16 209 9 399
-15z m2711 -1278 l0 -1299 -225 -38 c-494 -83 -960 -197 -1207 -297 l-53 -22
0 1215 0 1214 87 76 c263 230 589 374 963 425 110 15 287 28 370 26 l65 -1 0
-1299z m-3255 -155 c0 -708 4 -1220 9 -1234 12 -31 44 -42 161 -57 293 -37
985 -184 1120 -239 17 -7 -218 -10 -742 -10 l-768 -1 0 1375 0 1375 110 0 110
0 0 -1209z m3620 -166 l0 -1375 -767 1 c-761 0 -768 1 -716 19 172 61 872 207
1133 235 75 9 105 22 119 51 8 18 11 357 11 1234 l0 1210 110 0 110 0 0 -1375z"/>
<path d="M1243 3600 c-45 -19 -56 -75 -20 -107 18 -16 46 -22 147 -32 267 -25
488 -94 742 -234 47 -26 97 -47 110 -47 55 0 79 78 36 117 -57 50 -257 149
-411 202 -128 44 -312 86 -422 96 -38 3 -90 8 -115 10 -25 2 -55 0 -67 -5z"/>
<path d="M1226 3106 c-32 -31 -33 -66 -3 -93 17 -16 47 -22 147 -32 268 -25
506 -99 739 -231 49 -27 97 -50 108 -50 35 0 63 29 63 65 0 45 -39 76 -183
148 -248 124 -483 189 -764 212 -79 7 -83 6 -107 -19z"/>
<path d="M1225 2625 c-31 -30 -32 -65 -2 -92 18 -16 46 -22 147 -31 270 -25
510 -100 739 -231 48 -28 97 -51 107 -51 36 0 64 29 64 65 0 41 -12 54 -101
105 -223 131 -507 222 -779 250 -137 14 -148 13 -175 -15z"/>
<path d="M1225 2145 c-31 -30 -32 -59 -2 -89 20 -19 37 -24 122 -30 265 -18
553 -110 806 -258 49 -28 61 -31 85 -23 33 12 50 50 40 88 -4 18 -28 38 -83
71 -234 136 -506 225 -785 255 -140 16 -155 14 -183 -14z"/>
<path d="M3725 3599 c-230 -22 -470 -92 -685 -200 -168 -84 -200 -108 -200
-153 0 -39 26 -66 62 -66 14 0 63 22 109 48 234 133 473 208 740 233 101 9
128 15 147 32 29 27 28 62 -3 92 -26 27 -28 27 -170 14z"/>
<path d="M3770 3124 c-305 -29 -565 -107 -805 -242 -105 -58 -125 -77 -125
-118 0 -36 29 -64 65 -64 11 0 56 21 100 46 226 130 480 210 745 235 101 10
129 16 148 32 29 27 28 62 -3 92 -24 25 -36 27 -125 19z"/>
<path d="M3706 2639 c-256 -27 -548 -122 -765 -249 -89 -51 -101 -64 -101
-105 0 -36 28 -65 63 -65 10 0 66 27 125 60 232 129 490 206 757 226 71 5 95
11 113 27 29 27 28 62 -3 92 -27 28 -47 29 -189 14z"/>
<path d="M3700 2159 c-203 -23 -430 -87 -613 -172 -111 -52 -216 -113 -234
-135 -21 -26 -16 -79 9 -96 34 -24 55 -20 137 28 227 134 519 224 776 242 85
6 102 11 122 30 30 30 29 59 -2 89 -27 28 -56 30 -195 14z"/>
<path d="M4869 2811 c-24 -19 -24 -21 -27 -260 l-3 -241 25 -25 c30 -30 69
-32 96 -5 19 19 20 33 20 263 0 242 0 244 -23 265 -29 27 -57 28 -88 3z"/>
<path d="M4860 2023 c-20 -26 -21 -35 -18 -266 3 -237 3 -239 27 -258 30 -24
52 -24 85 2 l26 20 0 245 c0 231 -1 245 -20 264 -29 29 -74 26 -100 -7z"/>
</g>
</svg>

    );
}
export default ElmSchoolIcon;