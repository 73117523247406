import React from 'react'
import PencilIcon from '../../asssets/icons/PencilIcon'

const UploadProfilePhoto = ({imageLink} : {imageLink : string | undefined}) => {
  return (
    <div className="user-profile-img">
        <img
          alt="Profile Photo"
          src={imageLink === undefined || imageLink === "" ? require("../../asssets/images/user_default_big.png") : imageLink}
          className="img-fluid"
        />
        <a
          data-tip="change profile image"
          className="change-profile-picture"
          style={{ cursor: "pointer" }}
          //currentitem="false"
        >
          <PencilIcon/>
        </a>
      </div>
  )
}

export default UploadProfilePhoto