import React from 'react'

function Reportpage() {
  return (
    <div className="col-lg-9">
  <p className="top-header-my-account top-header-text-left">
    View <span>Report</span>
  </p>
  <p className="host-course-webinar-btn">
    <a href="/add-course/">
      Add Course
      <i className="fa fa-plus ml-2" />
    </a>
    <a href="/hosted-webinars-create/">
      Request Webinar
      <i className="fa fa-plus ml-2" />
    </a>
  </p>
  <div className="right-containt-section">
    <div>
      <div className="row">
        <div className=" col-lg-12">
          <a href="/dashboard">
            <span className="support-back-btn pull-right">
              <i className="fa fa-angle-double-left" aria-hidden="true" />
              Back
            </span>
          </a>
        </div>
        <div className="col-lg-12">
          <form className="support-index-page-form">
            <div className="mb-3 row">
              <div className="form-group col">
                <label
                  className="add-course-form-label form-label"
                  htmlFor="formPeriod"
                >
                  Period
                </label>
                <select name="period" id="formPeriod" className="form-control">
                  <option value="">Select anyone</option>
                  <option value="current_month">Current Month</option>
                  <option value="last_month">Last Month</option>
                  <option value="last_quarter">Last Quarter</option>
                  <option value="last_6_month">Last 6 Month</option>
                  <option value="last_year">Last Year</option>
                </select>
              </div>
              <div className="form-group col">
                <label
                  className="add-course-form-label form-label"
                  htmlFor="formItemName"
                >
                  Product Type
                </label>
                <select
                  name="item_type"
                  id="formItemName"
                  className="form-control"
                >
                  <option value={1}>Course</option>
                </select>
              </div>
              <div className="form-group col">
                <label
                  className="add-course-form-label form-label"
                  htmlFor="formItemName"
                >
                  Product Name
                </label>
                <select
                  name="item_name"
                  id="formItemName"
                  className="form-control"
                >
                  <option value={0}>Show All</option>
                  <option value={1466}>
                    Masterclass on Short-term Momentum Trading
                  </option>
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col">
                <label
                  className="add-course-form-label form-label"
                  htmlFor="formfromDate"
                >
                  From
                </label>
                <input
                  name="fromDate"
                  min="2000-01-01"
                  max="2024-09-10"
                  type="date"
                  id="formfromDate"
                  className="form-control"
                  defaultValue="2024-09-01"
                />
              </div>
              <div className="form-group col">
                <label
                  className="add-course-form-label form-label"
                  htmlFor="formtoDate"
                >
                  To
                </label>
                <input
                  name="toDate"
                  min="2000-01-01"
                  max="2024-09-10"
                  type="date"
                  id="formtoDate"
                  className="form-control"
                  defaultValue="2024-09-10"
                />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <button
                type="submit"
                className="author-section-btn author-red-btn mt-4 mb-3 btn btn-primary"
              >
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="col-lg-12">
          <div className="text-center mb-1 mt-3">
            <p className="reported-date">
              This report has started from 2nd November
            </p>
          </div>
          <div className="section-header">
            <p>
              My <span className="blue-text">Courses</span>
            </p>
          </div>
          <table className="tickets-hitory-table table-responsive">
            <thead>
              <tr>
                <th>Product</th>
                <th>Enrolments</th>
                <th>Revenue (%)</th>
                <th>Revenue Sharing</th>
                <th>Your Earning</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8}>No Record Found</td>
              </tr>
              <tr>
                <td colSpan={4} className="text-right">
                  Total Earning
                </td>
                <td colSpan={3} className="text-right">
                  0
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-12">
          <div className="section-header">
            <p>
              Third <span className="blue-text">Party Courses </span>
            </p>
          </div>
          <table className="tickets-hitory-table table-responsive">
            <thead>
              <tr>
                <th>Product</th>
                <th>Enrolments</th>
                <th>Revenue (%)</th>
                <th>Revenue Sharing</th>
                <th>Your Earning</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8}>No Record Found</td>
              </tr>
              <tr>
                <td colSpan={4} className="text-right">
                  Total Earning
                </td>
                <td colSpan={3} className="text-right">
                  0
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Reportpage