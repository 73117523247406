import React from "react";
import { Link } from "react-router-dom";

import PowerIcon from "../../asssets/icons/PowerIcon";
import BarIcon from "../../asssets/icons/BarIcon";

const Header = ({
  setIsClickedMobileMenu,
}: {
  setIsClickedMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="container-fluid px-0 overflow-hidden">
      <div className="top-header">
        <div className="row">
          <div className="col-lg-2 col-4 text-center">
            <Link
              to={``}
              onClick={() => setIsClickedMobileMenu && setIsClickedMobileMenu(true)}
              className="left-menu-open-btn"
            >
              {/* <i className="fa fa-bars" aria-hidden="true" /> */}
              <BarIcon />
            </Link>
            <div className="elm-logo">
              <Link
                to={process.env.REACT_APP_NEXT_HOME_URL as string}
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={require("../../asssets/images/new_elm_logo.png")}
                  alt="logo"
                  className="img-fluid lms-elm-icon"
                />
                <img
                  src={require("../../asssets/images/small-elm-logo.png")}
                  alt="logo"
                  className="img-fluid logo-for-mobile"
                />
              </Link>
            </div>
          </div>
          <div
            className="col-lg-10 col-8"
            style={{ boxShadow: "rgb(243, 243, 243) 8px 0px 8px inset" }}
          >
            <div className="help-settings">
              <span className="course-webinar-link-top">
                <Link
                  to={process.env.REACT_APP_NEXT_HOME_URL + "courses"}
                  rel="noreferrer"
                  target="_blank"
                >
                  Courses
                </Link>
              </span>
              <span className="course-webinar-link-top">
                <Link
                  to={process.env.REACT_APP_NEXT_HOME_URL + "webinars"}
                  rel="noreferrer"
                  target="_blank"
                >
                  Webinars
                </Link>
              </span>
              <span />
              <span className="go-to-my-account-dashboard">
                <Link
                  to={process.env.REACT_APP_NEXT_HOME_URL + "api/auth/signout"}
                  style={{ cursor: "pointer" }}
                >
                  <span>Logout</span>
                  <PowerIcon />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
