import React from 'react';

function MyWebinarIcon() {
    return (
        <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25003 6.5625H10.3594V11.9531C10.3594 12.3414 10.6742 12.6562 11.0625 12.6562H20.4375C20.8258 12.6562 21.1407 12.3414 21.1407 11.9531V3.51562C21.1407 3.12731 20.8258 2.8125 20.4375 2.8125H11.0625C10.898 2.8125 10.7388 2.87016 10.6124 2.97548L7.79989 5.31923C7.5725 5.50875 7.48813 5.82037 7.58891 6.09877C7.68969 6.37716 7.95402 6.5625 8.25003 6.5625ZM11.3171 4.21875H19.7344V11.25H11.7657V5.85938C11.7657 5.47106 11.4508 5.15625 11.0625 5.15625H10.1921L11.3171 4.21875Z" fill="currentColor"/>
        <path d="M13.875 7.03125H17.625C18.0133 7.03125 18.3281 6.71644 18.3281 6.32812C18.3281 5.93981 18.0133 5.625 17.625 5.625H13.875C13.4867 5.625 13.1719 5.93981 13.1719 6.32812C13.1719 6.71644 13.4867 7.03125 13.875 7.03125Z" fill="currentColor"/>
        <path d="M13.875 9.84375H17.625C18.0133 9.84375 18.3281 9.52894 18.3281 9.14062C18.3281 8.75231 18.0133 8.4375 17.625 8.4375H13.875C13.4867 8.4375 13.1719 8.75231 13.1719 9.14062C13.1719 9.52894 13.4867 9.84375 13.875 9.84375Z" fill="currentColor"/>
        <path d="M21.8906 0H2.10938C0.946266 0 0 0.946266 0 2.10938V17.2031C0 18.3662 0.946266 19.3125 2.10938 19.3125H8.44214C8.31042 20.4156 7.87195 21.472 7.17431 22.344L6.97453 22.5938H5.4375C5.04919 22.5938 4.73438 22.9086 4.73438 23.2969C4.73438 23.6852 5.04919 24 5.4375 24H18.5625C18.9508 24 19.2656 23.6852 19.2656 23.2969C19.2656 22.9086 18.9508 22.5938 18.5625 22.5938H17.0254L16.8256 22.344C16.128 21.472 15.6895 20.4156 15.5578 19.3125H21.8906C23.0537 19.3125 24 18.3662 24 17.2031V2.10938C24 0.946266 23.0537 0 21.8906 0ZM2.10938 1.40625H21.8906C22.2783 1.40625 22.5938 1.72167 22.5938 2.10938V14.5312H9.89062V14.2969C9.89062 13.0602 9.2482 11.9714 8.28009 11.3445C8.69798 10.8862 8.95312 10.277 8.95312 9.60938C8.95312 8.1878 7.79658 7.03125 6.375 7.03125C4.95342 7.03125 3.79688 8.1878 3.79688 9.60938C3.79688 10.277 4.05202 10.8862 4.46991 11.3445C3.5018 11.9714 2.85938 13.0602 2.85938 14.2969V14.5312H1.40625V2.10938C1.40625 1.72167 1.72167 1.40625 2.10938 1.40625ZM5.20312 9.60938C5.20312 8.9632 5.72883 8.4375 6.375 8.4375C7.02117 8.4375 7.54688 8.9632 7.54688 9.60938C7.54688 10.2555 7.02117 10.7812 6.375 10.7812C5.72883 10.7812 5.20312 10.2555 5.20312 9.60938ZM6.375 12.1875C7.53811 12.1875 8.48438 13.1338 8.48438 14.2969V14.5312H4.26562V14.2969C4.26562 13.1338 5.21189 12.1875 6.375 12.1875ZM15.277 22.5938H8.72302C9.35391 21.6074 9.74419 20.4784 9.85673 19.3125H14.1433C14.2559 20.4784 14.6461 21.6074 15.277 22.5938ZM21.8906 17.9062H2.10938C1.72167 17.9062 1.40625 17.5908 1.40625 17.2031V15.9375H22.5938V17.2031C22.5938 17.5908 22.2783 17.9062 21.8906 17.9062Z" fill="currentColor"/>
        </svg>

    );
}
export default MyWebinarIcon;