import React from 'react'

export default function CourseDiscussion() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8906 9.89063H21.1406V3.5625C21.1406 2.39939 20.1944 1.45312 19.0312 1.45312H2.10938C0.946266 1.45312 0 2.39939 0 3.5625V14.8125C0 15.9756 0.946266 16.9219 2.10938 16.9219H2.8125V20.4375C2.8125 20.7219 2.98383 20.9783 3.24656 21.0871C3.50934 21.1959 3.81173 21.1358 4.01283 20.9347L8.02561 16.9219H11.2969V17.625C11.2969 18.7881 12.2431 19.7344 13.4062 19.7344H17.3338L19.9403 22.3409C20.1417 22.5424 20.4443 22.602 20.7066 22.4933C20.9693 22.3845 21.1406 22.1281 21.1406 21.8438V19.7344H21.8906C23.0537 19.7344 24 18.7881 24 17.625V12C24 10.8369 23.0537 9.89063 21.8906 9.89063ZM7.73438 15.5156C7.54786 15.5156 7.36908 15.5897 7.23722 15.7215L4.21875 18.74V16.2188C4.21875 15.8304 3.90394 15.5156 3.51562 15.5156H2.10938C1.72167 15.5156 1.40625 15.2002 1.40625 14.8125V3.5625C1.40625 3.1748 1.72167 2.85938 2.10938 2.85938H19.0312C19.419 2.85938 19.7344 3.1748 19.7344 3.5625V9.89063H13.4062C12.2431 9.89063 11.2969 10.8369 11.2969 12V15.5156H7.73438ZM22.5938 17.625C22.5938 18.0127 22.2783 18.3281 21.8906 18.3281H20.4375C20.0492 18.3281 19.7344 18.6429 19.7344 19.0313V20.1463L18.1222 18.5341C17.9903 18.4022 17.8115 18.3281 17.625 18.3281H13.4062C13.0185 18.3281 12.7031 18.0127 12.7031 17.625V12C12.7031 11.6123 13.0185 11.2969 13.4062 11.2969H21.8906C22.2783 11.2969 22.5938 11.6123 22.5938 12V17.625Z" fill="currentColor"/>
            <path d="M18.5341 13.6122L16.9219 15.2244L16.3644 14.6669C16.0898 14.3923 15.6446 14.3923 15.37 14.6669C15.0954 14.9415 15.0954 15.3867 15.37 15.6612L16.4247 16.7159C16.562 16.8532 16.7419 16.9219 16.9219 16.9219C17.1018 16.9219 17.2818 16.8533 17.419 16.7159L19.5284 14.6066C19.803 14.332 19.803 13.8868 19.5284 13.6122C19.2539 13.3376 18.8087 13.3376 18.5341 13.6122Z" fill="currentColor"/>
            <path d="M9.1875 11.2969H4.92188C4.53356 11.2969 4.21875 11.6117 4.21875 12C4.21875 12.3883 4.53356 12.7031 4.92188 12.7031H9.1875C9.57581 12.7031 9.89062 12.3883 9.89062 12C9.89062 11.6117 9.57581 11.2969 9.1875 11.2969Z" fill="currentColor"/>
            <path d="M10.5938 8.48438H4.92188C4.53356 8.48438 4.21875 8.79919 4.21875 9.1875C4.21875 9.57581 4.53356 9.89062 4.92188 9.89062H10.5938C10.9821 9.89062 11.2969 9.57581 11.2969 9.1875C11.2969 8.79919 10.9821 8.48438 10.5938 8.48438Z" fill="currentColor"/>
            <path d="M4.92188 7.07812H16.2188C16.6071 7.07812 16.9219 6.76331 16.9219 6.375C16.9219 5.98669 16.6071 5.67188 16.2188 5.67188H4.92188C4.53356 5.67188 4.21875 5.98669 4.21875 6.375C4.21875 6.76331 4.53356 7.07812 4.92188 7.07812Z" fill="currentColor"/>
        </svg>


    )
}
