import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../types/CommonTypes";
import { UserSliceType } from "../types/UserTypes";
import { fetchDetails } from "../utils/fetchRequests";

const initialState: UserSliceType = {
  loading: true,
  userDetails: null,
  error: "",
  errorCode: 0
};

export const fetchUserData = createAsyncThunk(
  "data/fetchUserData",
  async () => {
    let response, responseCode
    try {
      console.log("inside user slice before api call");
      // console.log(process.env.REACT_APP_LMS_API_URL as string)
       response = await fetch(
        `${process.env.REACT_APP_LMS_API_URL as string}users/user-info`,
        { credentials: "include" }
      ).then((r) => {
        responseCode = r.status
        return r.json()
      });
    } catch (error) {
      return {
        response : response,
        responseCode : responseCode
      }
    } finally {
      return {
        response : response,
        responseCode : responseCode
      }
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUserdata: (state) => {
      console.log("state is:", state);
      state.loading = true;
    },
    setUserError: (state) => {
      console.log("user error: ", state);
      state.error = "Something is wrong";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        console.log("inside loading state");
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        console.log("inside fulfilled block");
        state.loading = false;
        if (action.payload.response && action.payload.responseCode === 200) {
          state.userDetails = action.payload.response;
          state.loading = false;
          state.error = "";
        } else {
          state.error = "Something is wrong";
          state.errorCode = action.payload.responseCode
          
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        console.log("inside rejected block");
        state.loading = false;
        state.error = action.error.message;
        state.errorCode = action.error.code
      });
  },
});

export const { loadUserdata, setUserError } = userSlice.actions;
