import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


// const baseQuery = fetchBaseQuery({
//   baseUrl: 'https://api.your-really-great-app.com/v1/',
//   prepareHeaders: (headers, { getState }) => {
//     const token = (getState() as RootState).auth.token;
//     // If we have a token set in state, let's assume that we should be passing it.
//     if (token) {
//       headers.set('authorization', `Bearer ${token}`);
//     }
//     return headers;
//   },
// })

export interface webinarType{
  [key:string]: any
}
const initialState: webinarType={
  value:[],
  allWebinarData:{
    subscriptionData : {
      loading : true
    },
    webinarUpcomingData : [],
    webinarPastData: []
  }
}

export const webinarSlice=createSlice({
  name:'webinar',
  initialState,
  reducers:{
    setCategory:(state, action: PayloadAction<webinarType>)=>{
      console.log("list is: ",state)
      state.value.push(action.payload)
    },
    setWebinarSubscriptionData : (state, action: PayloadAction<webinarType>) => {
      let payload = action.payload;
      state.allWebinarData.subscriptionData = {loading : false, ...payload}
    },

    setWebinarUpcomingData : (state, action: PayloadAction<webinarType>) => {
      state.allWebinarData.webinarUpcomingData= [...action.payload.user_webinar]
    },
    setWebinarPastData : (state, action: PayloadAction<webinarType>) => {
      state.allWebinarData.webinarPastData = [...action.payload.user_webinar]

    }
  }
})


export const {setCategory, setWebinarSubscriptionData, setWebinarUpcomingData, setWebinarPastData} = webinarSlice.actions
