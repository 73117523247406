import React from 'react';

function MyCourseIcon() {
    return (
        <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M23.9813 7.94604C23.9325 7.45365 23.635 7.02888 23.1798 6.81647L12.5895 1.82625C12.2114 1.64985 11.7777 1.64985 11.3974 1.82738L0.81183 6.81535C-0.281353 7.32546 -0.263676 8.87731 0.823552 9.37017L4.53922 11.071V17.4763C4.53922 18.2519 5.17026 18.883 5.94591 18.883H18.0434C18.8191 18.883 19.4501 18.2519 19.4501 17.4763V11.071L22.5917 9.63299V21.6026C22.5917 21.9911 22.9066 22.306 23.295 22.306C23.6835 22.306 23.9984 21.9911 23.9984 21.6026C23.9983 7.141 24.0078 8.06575 23.9813 7.94604ZM1.40667 8.09004C1.41136 8.08783 9.44471 4.30249 11.9947 3.10099L21.1076 7.39504H11.9947C11.6062 7.39504 11.2913 7.70995 11.2913 8.09839C11.2913 8.48682 11.6062 8.80173 11.9947 8.80173H21.0285L11.997 12.9376C1.24964 8.01816 1.40198 8.09004 1.40667 8.09004ZM18.0434 17.4763H5.94586V11.7149C11.8493 14.4171 11.5683 14.3431 11.994 14.3431C12.4331 14.3431 12.1916 14.3934 18.0434 11.7149V17.4763Z"  />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default MyCourseIcon;