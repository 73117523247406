import React from "react";
import { Link } from "react-router-dom";

const NcdexCertificate = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="/static/media/Certificate-1.a09740a0.png"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    NSE Certified Online Technical Analysis
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn elearn-market-btn">
                      Request Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="blob:http://elm.dev.com/d8252a3b-5b12-4b4f-a0da-28b0aebd06d3"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Certification in Online Currency and Commodities
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-6 pt-2 pb-1 text-start">
                    <Link
                      className="elm-certificate-download-btn"
                      to={""}
                      data-tip="download"
                    >
                      <i className="fa fa-download" aria-hidden="true" />{" "}
                      Download
                    </Link>
                  </div>
                  <div className="col-6 pt-2 pb-1 text-end">
                    <Link
                      className="elm-certificate-download-btn"
                      to="http://dev.com/share/bnNlLTEzOQ%253D%253D/2"
                      target="_blank"
                      data-tip="share"
                    >
                      <i className="fa fa-share-alt" aria-hidden="true" /> Share
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="/static/media/Certificate-1.a09740a0.png"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Certification in Online Macroeconomics for Financial Markets
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn elearn-market-btn">
                      Request Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="/static/media/Certificate-1.a09740a0.png"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Certification in Online Finance for Non-Finance Managers
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn elearn-market-btn">
                      Request Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="blob:http://elm.dev.com/6bd82588-452b-4a8d-919f-b3f16dc45c31"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Basic Of computer Application
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-6 pt-2 pb-1 text-start">
                    <Link
                      className="elm-certificate-download-btn"
                      to={""}
                      data-tip="download"
                    >
                      <i className="fa fa-download" aria-hidden="true" />{" "}
                      Download
                    </Link>
                  </div>
                  <div className="col-6 pt-2 pb-1 text-end">
                    <Link
                      className="elm-certificate-download-btn"
                      to="http://dev.com/share/bnNlLTg4/2"
                      target="_blank"
                      data-tip="share"
                    >
                      <i className="fa fa-share-alt" aria-hidden="true" /> Share
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="blob:http://elm.dev.com/f88861c3-3c87-4b05-a72c-5c2ef14555a6"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Online NSE Certified Capital Market Professional (E-NCCMP)
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-6 pt-2 pb-1 text-start">
                    <Link
                      className="elm-certificate-download-btn"
                      to={""}
                      data-tip="download"
                    >
                      <i className="fa fa-download" aria-hidden="true" />{" "}
                      Download
                    </Link>
                  </div>
                  <div className="col-6 pt-2 pb-1 text-end">
                    <Link
                      className="elm-certificate-download-btn"
                      to="http://dev.com/share/bnNlLTM3/2"
                      target="_blank"
                      data-tip="share"
                    >
                      <i className="fa fa-share-alt" aria-hidden="true" /> Share
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src="/static/media/Certificate-1.a09740a0.png"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="certificate-for-course">
                    Certification in Online Technical Analysis
                  </p>
                </div>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn elearn-market-btn">
                      Request Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NcdexCertificate;
