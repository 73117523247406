import React from 'react'

function Profile() {
  return (
    <>
  <nav className="certificate-top-tab nav nav-tabs" role="tablist">
    <a
      id="controlled-tab-example-tab-view_profile"
      href="#"
      role="tab"
      data-rb-event-key="view_profile"
      aria-controls="controlled-tab-example-tabpane-view_profile"
      aria-selected="true"
      className="nav-item nav-link active"
    >
      View Profile
    </a>
    <a
      id="controlled-tab-example-tab-order_history"
      href="#"
      role="tab"
      data-rb-event-key="order_history"
      aria-controls="controlled-tab-example-tabpane-order_history"
      tabIndex={-1}
      aria-selected="false"
      className="nav-item nav-link"
    >
      Order History
    </a>
    <a
      id="controlled-tab-example-tab-preference"
      href="#"
      role="tab"
      data-rb-event-key="preference"
      aria-controls="controlled-tab-example-tabpane-preference"
      tabIndex={-1}
      aria-selected="false"
      className="nav-item nav-link"
    >
      Preference
    </a>
  </nav>
  <div className="tab-content">
    <div
      id="controlled-tab-example-tabpane-view_profile"
      aria-labelledby="controlled-tab-example-tab-view_profile"
      role="tabpanel"
      aria-hidden="false"
      className="fade tab-pane active show"
    >
      <div>
        <div>
          <div className="row">
            <div className="col-lg-3">
              <p className="profile-topic">name</p>
            </div>
            <div className="col-lg-9">
              <p
                className="profile-topic-details"
                style={{ textTransform: "capitalize" }}
              >
                Sulagna Ghosh
              </p>
            </div>
            <div className="col-lg-3">
              <p className="profile-topic">Member since</p>
            </div>
            <div className="col-lg-9">
              <p className="profile-topic-details">16-Dec-21</p>
            </div>
            <div className="col-lg-3">
              <p className="profile-topic">Email ID</p>
            </div>
            <div className="col-lg-9">
              <p className="profile-topic-details">
                sulagna@stockedge.com{" "}
                <span style={{ fontSize: 10 }}>
                  <i
                    className="fa fa-check ml-3"
                    style={{ color: "rgb(0, 255, 0)" }}
                  />{" "}
                  Verified on 31-Dec-21
                </span>
              </p>
            </div>
            <div className="col-lg-3">
              <p className="profile-topic">Contact No</p>
            </div>
            <div className="col-lg-9">
              <p className="profile-topic-details">
                8777794741
                <span style={{ fontSize: 10 }}>
                  <i
                    className="fa fa-check ml-3"
                    style={{ color: "rgb(0, 255, 0)" }}
                  />{" "}
                  Verified on 9-Sept-24
                </span>
              </p>
            </div>
            <div className="col-lg-3">
              <p className="profile-topic">Address</p>
            </div>
            <div className="col-lg-9">
              <p className="profile-topic-details" />
            </div>
            <div className="col-lg-12 text-center">
              <button
                type="button"
                className="elm-btn elm-btn-primary-blue mt-3 mb-3 btn btn-primary"
              >
                Edit
              </button>
              &nbsp;
              <button
                className="elm-btn btn-light mt-3 mb-3 ml-2"
                style={{ boxShadow: "rgba(29, 69, 138, 0.21) 0px 0px 3px" }}
              >
                <i className="fa fa-key" aria-hidden="true" /> Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="controlled-tab-example-tabpane-order_history"
      aria-labelledby="controlled-tab-example-tab-order_history"
      role="tabpanel"
      aria-hidden="true"
      className="fade tab-pane"
    >
      <div>
        <div className="row">
          <div className="col-lg-12">
            <table className="tickets-hitory-table table-responsive">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice No</th>
                  <th>Particulars</th>
                  <th>Amount</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>You have no order history. </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      id="controlled-tab-example-tabpane-preference"
      aria-labelledby="controlled-tab-example-tab-preference"
      role="tabpanel"
      aria-hidden="true"
      className="fade tab-pane"
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="row">
            <div className="col-lg-12 mt-4">
              <span className="settings-switch-btn mr-2">
                <div className="switch btn on btn-primary">
                  <div className="switch-group">
                    <span className="switch-on btn btn-primary">Yes</span>
                    <span className="switch-off btn btn-light">No</span>
                    <span className="switch-handle btn btn-light" />
                  </div>
                </div>
              </span>{" "}
              <label>Receive Promotional Email</label>
            </div>
            <div className="col-lg-12 mt-4">
              <span className="settings-switch-btn mr-2">
                <div className="switch btn off btn-light">
                  <div className="switch-group">
                    <span className="switch-on btn btn-primary">Yes</span>
                    <span className="switch-off btn btn-light">No</span>
                    <span className="switch-handle btn btn-light" />
                  </div>
                </div>
              </span>{" "}
              <label>Receive Updates in WhatsApp </label>
            </div>
            <div className="col-lg-12 mt-3">
              <a href="javascript:void(0)" className="delete-account-btn-elm">
                Delete Account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default Profile