import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { CourseType, CourseSliceType } from "../types/CourseTypes";

const initialState : CourseSliceType = {
  loadingIncompletedCourse: true,
  loadingCompletedCourse : true,
  completedCourses: {
    pagination: {
      isLoading : false,
      total : 0,
      pageNo: 1,
      isNext : false
    },
    courses: []
},
onGoingCourses: {
  pagination: {
    isLoading : false,
    total : 0,
    pageNo: 1,
    isNext : false
  },
  courses: []
},
  error : ""
};
export const fetchIncompletedCourseData = createAsyncThunk(
  "getIncompletedCourse",
  async (pageNo: number) => {
    try {
      let response = await fetchDetails(
        `courses/get-user-purchased-courses-ongoing/${pageNo}`
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);
export const fetchCompletedCourseData =  createAsyncThunk(
  "getCompletedCourse",
  async (pageNo: number) => {
    try {
      let response = await fetchDetails(
        `courses/get-user-purchased-courses-completed/${pageNo}`
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);
export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    loadCourseData: (state) => {
      state.loadingIncompletedCourse = true;
      state.loadingCompletedCourse = true;
    },
    loadPagination : (state, action) => {
      if(action.payload.ongoing === true){
         state.onGoingCourses.pagination.isLoading =true
      }else{
        state.completedCourses.pagination.isLoading = true
      }
    },
    setCourseError: (state) => {
      state.error = "something wrong";
    },
    setOnGoingPageNo: (state, action) => {
      state.onGoingCourses.pagination.pageNo = action.payload
    },
    setCompletedPageNo : (state, action) => {
      state.completedCourses.pagination.pageNo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncompletedCourseData.pending, (state) => {
        state.loadingIncompletedCourse = state.onGoingCourses.pagination.pageNo === 1 ? true : false;
        state.onGoingCourses.pagination.isLoading = state.onGoingCourses.pagination.pageNo > 1 ? true : false;
      })

      .addCase(fetchIncompletedCourseData.fulfilled, (state, action) => {
        if(state.error === ""){
        let responseCode = action.payload && action.payload.responseCode;
        if (responseCode === 200) {
          let courseData: CourseType[] =
            action.payload?.response.invoiceCourses;
          state.onGoingCourses.pagination.isNext = action.payload?.response.isNext;
          state.onGoingCourses.pagination.total = action.payload?.response.total;

          courseData &&
            courseData.map((course) => {
              const datediff = Math.floor(
                (new Date(course.expiryDate).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              course.noOfDays = datediff + 1;
              return course;
            });
          
          state.onGoingCourses.courses = [
            ...state.onGoingCourses.courses,
            ...courseData,
          ];

          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      }

        state.loadingIncompletedCourse = false;
        state.onGoingCourses.pagination.isLoading = false
      })
      .addCase(fetchIncompletedCourseData.rejected, (state, action) => {
        state.loadingIncompletedCourse = false;
        state.onGoingCourses.pagination.isLoading = false;
        state.error = action.error.message;
      })
      
      .addCase(fetchCompletedCourseData.pending, (state) => {
        state.loadingCompletedCourse = state.completedCourses.pagination.pageNo === 1 ? true : false;
        state.completedCourses.pagination.isLoading = state.completedCourses.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchCompletedCourseData.fulfilled, (state, action) => {
        if(state.error === "") {
        let responseCode = action.payload && action.payload.responseCode;
        if (responseCode === 200) {
          let courseData: CourseType[] =
            action.payload?.response.invoiceCourses;
          state.completedCourses.pagination.isNext = action.payload?.response.isNext;
          state.completedCourses.pagination.total = action.payload?.response.total;

          courseData &&
            courseData.map((course) => {
              const datediff = Math.floor(
                (new Date(course.expiryDate).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              course.noOfDays = datediff + 1;
              return course;
            });
          
          state.completedCourses.courses = [
            ...state.completedCourses.courses,
            ...courseData,
          ];

          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      }
        state.loadingCompletedCourse = false;
        state.completedCourses.pagination.isLoading = false;
      })
      .addCase(fetchCompletedCourseData.rejected, (state, action) => {
        state.loadingCompletedCourse = false;
        state.error = action.error.message;
      });

  },
});
export const { loadCourseData, loadPagination,  setCourseError, setOnGoingPageNo, setCompletedPageNo } =
  courseSlice.actions;
