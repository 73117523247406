import React from 'react'

function MyOffers() {
  return (
    <div>
  <div className="col-lg-12 mb-4">
    <div className="section-header">
      <p>
        offers From <span className="blue-text">kotak securities</span>
      </p>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="kotak_offer_box">
          <div className="p-3">
            <div className="text-right">
              <img
                alt=""
                src="https://d19hjqu7ppizo0.cloudfront.net/uploads/other_pic/kotak_60x30_1696323470.webp"
                className="img-fluid kotak_logo"
              />
            </div>
            <p className="offer_validity">
              12<span className="month">Months</span>
            </p>
            <p className="kotak_offer_name">Enjoy 0 Brokerage On Intraday</p>
            <p>
              <span className="kotak_offer_details">
                Open demat a/c with Kotak Securities!
              </span>
            </p>
            <p className="kotak_offer_btn">
              <a href="https://www.elearnmarkets.com/tmp/" target="_blank">
                View Offer Details{" "}
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-12 text-center mt-1 mb-4">
        <a
          target="_blank"
          href="https://www.elearnmarkets.in/courses"
          className="elm-btn elm-btn-primary btn btn-primary m-2"
        >
          View all courses
        </a>
        <a
          target="_blank"
          href="https://www.elearnmarkets.in/webinars"
          className="elm-btn elm-btn-primary-blue btn btn-primary m-2"
        >
          view all webinars
        </a>
      </div>
    </div>
    <div className="section-header">
      <p>
        Partner <span className="blue-text"> Offers</span>
      </p>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="available-coupons p-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 m-auto text-center">
              <img
                alt=""
                src="https://d19hjqu7ppizo0.cloudfront.net/uploads/other_pic/kotak_60x30_1696323470_1696571185.webp"
                className="img-fluid other_offer_logo"
              />
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="other_offer_box_details">
                <p className="available_coupon_name">Dummy Offer</p>
                <p className="offer_description">
                  Describe WHY customers would need your product and the value
                  they get from using it instead of just writing WHAT your
                  product does.
                </p>
                <p className="mt-3 checkbox_offer_details">
                  <input
                    type="checkbox"
                    name="check_opt_in"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  &nbsp; I am interested to avail this offer and agree to share
                  my data with Dummy Offer. I also agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.kotaksecurities.com/disclaimer/"
                  >
                    Terms &amp; Conditions
                  </a>{" "}
                  of the offer.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <a
                      href="https://www.kotaksecurities.com/disclaimer/"
                      target="_blank"
                      className="tandc_btn"
                    >
                      View T&amp;C
                    </a>
                  </div>
                  <div className="col-lg-6 text-right m-auto">
                    <p className="expires_date">Expires on 31 Dec</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="available-coupons p-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 m-auto text-center">
              <img
                alt=""
                src="https://d19hjqu7ppizo0.cloudfront.net/uploads/other_pic/Resize_Multipl_1697434257.webp"
                className="img-fluid other_offer_logo"
              />
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="other_offer_box_details">
                <p className="available_coupon_name">Reward On Multipl App</p>
                <p className="offer_description">
                  Unlock Digital Gold worth ₹500 on the Multipl App.
                </p>
                <p className="mt-3 checkbox_offer_details">
                  <input
                    type="checkbox"
                    name="check_opt_in"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  &nbsp; I am interested to avail this offer and agree to share
                  my data with Reward On Multipl App. I also agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.elearnmarkets.com/pages/multipl-terms-and-conditions"
                  >
                    Terms &amp; Conditions
                  </a>{" "}
                  of the offer.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <a
                      href="https://www.elearnmarkets.com/pages/multipl-terms-and-conditions"
                      target="_blank"
                      className="tandc_btn"
                    >
                      View T&amp;C
                    </a>
                  </div>
                  <div className="col-lg-6 text-right m-auto">
                    <p className="expires_date">Expires on 14 Nov</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="available-coupons p-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 m-auto text-center">
              <img
                alt=""
                src="https://d19hjqu7ppizo0.cloudfront.net/uploads/other_pic/hindustan-times9271_1702967121.webp"
                className="img-fluid other_offer_logo"
              />
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="other_offer_box_details">
                <p className="available_coupon_name">
                  Hindustan Times Subscription
                </p>
                <p className="offer_description">
                  Flat 30% discount on Digital subscription of Hindustan Times.
                </p>
                <p className="mt-3 checkbox_offer_details">
                  <input
                    type="checkbox"
                    name="check_opt_in"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  &nbsp; I am interested to avail this offer and agree to share
                  my data with Hindustan Times Subscription. I also agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.elearnmarkets.com/pages/mint-subscription-terms-and-conditions"
                  >
                    Terms &amp; Conditions
                  </a>{" "}
                  of the offer.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <a
                      href="https://www.elearnmarkets.com/pages/mint-subscription-terms-and-conditions"
                      target="_blank"
                      className="tandc_btn"
                    >
                      View T&amp;C
                    </a>
                  </div>
                  <div className="col-lg-6 text-right m-auto">
                    <p className="expires_date">Expires on 10 Jun</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="available-coupons p-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 m-auto text-center">
              <img
                alt=""
                src="https://d19hjqu7ppizo0.cloudfront.net/uploads/other_pic/tataimg_1724838626.webp"
                className="img-fluid other_offer_logo"
              />
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="other_offer_box_details">
                <p className="available_coupon_name">Tata 1MG</p>
                <p className="offer_description">
                  Get up to 8% off (no limit) + extra 17% coupon discount
                </p>
                <p className="mt-3 checkbox_offer_details">
                  <input
                    type="checkbox"
                    name="check_opt_in"
                    style={{ cursor: "pointer" }}
                  />{" "}
                  &nbsp; I am interested to avail this offer and agree to share
                  my data with Tata 1MG. I also agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.elearnmarkets.com/pages/tata-1mg"
                  >
                    Terms &amp; Conditions
                  </a>{" "}
                  of the offer.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <a
                      href="https://www.elearnmarkets.com/pages/tata-1mg"
                      target="_blank"
                      className="tandc_btn"
                    >
                      View T&amp;C
                    </a>
                  </div>
                  <div className="col-lg-6 text-right m-auto">
                    <p className="expires_date">Expires on 14 Aug</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section-header">
      <p>
        offers <span className="blue-text">on elearnmarkets</span>
      </p>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="available-coupons">
          <p className="text-center">
            <svg
              width={30}
              height={30}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_98_146)">
                <path
                  d="M20.9763 18.9795L13.9924 23.7003C13.1855 24.2413 12.104 24.0294 11.5652 23.2306L4.89959 13.3635C4.4944 12.7604 4.33753 12.026 4.44821 11.3195L5.32555 5.79039C5.49502 4.71225 6.54113 4.00805 7.61349 4.24842L13.0597 5.50415C13.7696 5.65713 14.3855 6.0879 14.7907 6.69108L21.446 16.5524C21.995 17.357 21.7832 18.4385 20.9763 18.9795Z"
                  fill="#404049"
                />
                <path
                  d="M2.42141 11.6114L6.49313 22.7983C6.82341 23.7058 7.82643 24.1735 8.73389 23.8432L16.6586 20.9588C17.5661 20.6285 18.0338 19.6255 17.7036 18.7181L13.6318 7.53112C13.3841 6.85046 12.8868 6.28733 12.2458 5.96247L7.25701 3.4283C6.28739 2.93069 5.09828 3.3635 4.66758 4.37078L2.48266 9.51596C2.19268 10.1797 2.17367 10.9307 2.42141 11.6114ZM5.63478 6.41961C5.4127 5.80943 5.72617 5.13719 6.33634 4.91511C6.94656 4.69301 7.6188 5.00648 7.84088 5.61665C8.06298 6.22687 7.74952 6.89912 7.1393 7.12122C6.52912 7.3433 5.85688 7.02983 5.63478 6.41961Z"
                  fill="#F7524B"
                />
                <path
                  opacity="0.1"
                  d="M16.6558 20.9577L8.73242 23.8454C7.82759 24.1758 6.82043 23.7038 6.48997 22.7989L2.42208 11.6102C2.17029 10.9335 2.19387 10.1782 2.48504 9.51722L3.21678 7.80194L3.4764 7.18823C2.24866 16.1321 11.5612 21.4867 16.9627 16.6852L17.7023 18.7153C17.7259 18.7782 17.7416 18.849 17.7574 18.912C17.962 19.7696 17.5056 20.6509 16.6558 20.9577Z"
                  fill="black"
                />
                <path
                  d="M9.42939 19.698C9.4098 19.698 9.38982 19.6965 9.36984 19.6934C9.15508 19.6611 9.00755 19.4606 9.0402 19.2454L10.2981 10.9564C10.3307 10.7417 10.5324 10.5922 10.746 10.6268C10.9608 10.6591 11.1083 10.8596 11.0757 11.0748L9.81781 19.3638C9.78823 19.5582 9.62072 19.698 9.42939 19.698ZM7.75239 14.5037C8.4205 14.2609 8.76666 13.5194 8.52385 12.8505C8.52347 12.8501 8.52347 12.8501 8.52347 12.8497C8.27989 12.1824 7.53724 11.8366 6.87028 12.0794C6.20178 12.3226 5.85601 13.0645 6.0992 13.733C6.28976 14.2563 6.78575 14.5813 7.31287 14.5813C7.45886 14.5813 7.60716 14.5567 7.75239 14.5037ZM7.78389 13.1191C7.87879 13.3803 7.74394 13.67 7.48345 13.7645C7.22335 13.8598 6.93367 13.7238 6.83839 13.4641C6.7435 13.2028 6.87873 12.9135 7.13922 12.8186C7.19569 12.7983 7.25371 12.7887 7.31057 12.7887C7.51611 12.7887 7.70974 12.9151 7.78389 13.1191ZM13.2456 18.2411C13.9141 17.9976 14.2599 17.2561 14.0171 16.5876C14.0171 16.5876 14.0171 16.5876 14.0171 16.5868C13.7727 15.9191 13.0297 15.5733 12.3635 15.8169C12.04 15.9344 11.7814 16.1711 11.6358 16.4838C11.4902 16.7958 11.4748 17.1462 11.5924 17.4697C11.7104 17.7932 11.947 18.0521 12.2597 18.1981C12.4326 18.2788 12.6178 18.3195 12.8038 18.3195C12.9528 18.3195 13.1019 18.2934 13.2456 18.2411ZM13.2779 16.8573C13.3724 17.1178 13.2371 17.4067 12.9767 17.502C12.8514 17.5473 12.7154 17.5419 12.5925 17.485C12.4703 17.4282 12.3781 17.3275 12.332 17.2007C12.2859 17.0747 12.292 16.938 12.3489 16.8158C12.4057 16.6944 12.5064 16.6022 12.6324 16.5561C12.6893 16.5353 12.7469 16.5253 12.8045 16.5253C13.0097 16.5253 13.2033 16.6529 13.2779 16.8573Z"
                  fill="#F0F0FC"
                />
                <path
                  d="M8.06091 0.0437934C7.49437 -0.0978291 6.89637 0.106752 6.36921 0.618162C5.88925 1.09029 5.51942 1.76695 5.31483 2.53015C5.11813 3.30128 5.1103 4.07235 5.30701 4.70969C5.51154 5.41785 5.93646 5.88993 6.50295 6.03943C6.621 6.07088 6.73899 6.08664 6.85703 6.08664C7.31341 6.08664 7.76979 5.8663 8.18678 5.46506C8.66673 5.00081 9.03657 4.31627 9.24115 3.55302C9.6739 1.86924 9.15456 0.334917 8.06091 0.0437934ZM8.4779 3.35631C8.31265 3.98582 8.0137 4.53656 7.64386 4.89852C7.45503 5.07947 7.07737 5.37059 6.69966 5.27618C6.31412 5.17389 6.13317 4.74114 6.05445 4.48935C5.90495 3.98582 5.91283 3.35631 6.07808 2.72686C6.24333 2.09741 6.54228 1.5545 6.91999 1.18471C7.07737 1.02733 7.37637 0.791292 7.69895 0.791292C7.75403 0.791292 7.80911 0.791292 7.85632 0.806997C8.42282 0.956495 8.81623 2.03445 8.4779 3.35631Z"
                  fill="#F0F0FC"
                />
                <path
                  d="M7.57441 3.58337C7.29117 4.03975 7.11804 4.48825 7.01575 4.88166C7.38558 4.9682 7.70028 5.23574 7.84191 5.61341C7.92062 5.82587 7.9285 6.04615 7.88129 6.25074C8.17241 6.07766 8.5894 5.77866 8.93561 5.28295C9.1402 4.99966 9.26607 4.72429 9.35265 4.48825L7.57441 3.58337Z"
                  fill="#F7524B"
                />
              </g>
              <defs>
                <clipPath id="clip0_98_146">
                  <rect width={24} height={24} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </p>
          <div>
            <span className="available-coupon-details">TESTING</span>
            <span>
              <a className="available-coupon-code">
                SKB585
                <span className="copy_icon" style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 488.3 488.3"
                  >
                    <g>
                      <g>
                        <path
                          d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124 C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124 c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"
                          fill="currentColor"
                        />
                        <path
                          d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227 c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6 V38.6C439.65,17.3,422.35,0,401.05,0z"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </span>
              </a>
            </span>
          </div>
          <p className="view_offer_btn">
            <a
              target="_blank"
              href="https://www.elearnmarkets.in/courses/display/mutual-fund-ab-hua-aasaan"
              style={{ cursor: "pointer" }}
            >
              View Offer{" "}
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            </a>
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="available-coupons">
          <p className="text-center">
            <svg
              width={30}
              height={30}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_98_146)">
                <path
                  d="M20.9763 18.9795L13.9924 23.7003C13.1855 24.2413 12.104 24.0294 11.5652 23.2306L4.89959 13.3635C4.4944 12.7604 4.33753 12.026 4.44821 11.3195L5.32555 5.79039C5.49502 4.71225 6.54113 4.00805 7.61349 4.24842L13.0597 5.50415C13.7696 5.65713 14.3855 6.0879 14.7907 6.69108L21.446 16.5524C21.995 17.357 21.7832 18.4385 20.9763 18.9795Z"
                  fill="#404049"
                />
                <path
                  d="M2.42141 11.6114L6.49313 22.7983C6.82341 23.7058 7.82643 24.1735 8.73389 23.8432L16.6586 20.9588C17.5661 20.6285 18.0338 19.6255 17.7036 18.7181L13.6318 7.53112C13.3841 6.85046 12.8868 6.28733 12.2458 5.96247L7.25701 3.4283C6.28739 2.93069 5.09828 3.3635 4.66758 4.37078L2.48266 9.51596C2.19268 10.1797 2.17367 10.9307 2.42141 11.6114ZM5.63478 6.41961C5.4127 5.80943 5.72617 5.13719 6.33634 4.91511C6.94656 4.69301 7.6188 5.00648 7.84088 5.61665C8.06298 6.22687 7.74952 6.89912 7.1393 7.12122C6.52912 7.3433 5.85688 7.02983 5.63478 6.41961Z"
                  fill="#F7524B"
                />
                <path
                  opacity="0.1"
                  d="M16.6558 20.9577L8.73242 23.8454C7.82759 24.1758 6.82043 23.7038 6.48997 22.7989L2.42208 11.6102C2.17029 10.9335 2.19387 10.1782 2.48504 9.51722L3.21678 7.80194L3.4764 7.18823C2.24866 16.1321 11.5612 21.4867 16.9627 16.6852L17.7023 18.7153C17.7259 18.7782 17.7416 18.849 17.7574 18.912C17.962 19.7696 17.5056 20.6509 16.6558 20.9577Z"
                  fill="black"
                />
                <path
                  d="M9.42939 19.698C9.4098 19.698 9.38982 19.6965 9.36984 19.6934C9.15508 19.6611 9.00755 19.4606 9.0402 19.2454L10.2981 10.9564C10.3307 10.7417 10.5324 10.5922 10.746 10.6268C10.9608 10.6591 11.1083 10.8596 11.0757 11.0748L9.81781 19.3638C9.78823 19.5582 9.62072 19.698 9.42939 19.698ZM7.75239 14.5037C8.4205 14.2609 8.76666 13.5194 8.52385 12.8505C8.52347 12.8501 8.52347 12.8501 8.52347 12.8497C8.27989 12.1824 7.53724 11.8366 6.87028 12.0794C6.20178 12.3226 5.85601 13.0645 6.0992 13.733C6.28976 14.2563 6.78575 14.5813 7.31287 14.5813C7.45886 14.5813 7.60716 14.5567 7.75239 14.5037ZM7.78389 13.1191C7.87879 13.3803 7.74394 13.67 7.48345 13.7645C7.22335 13.8598 6.93367 13.7238 6.83839 13.4641C6.7435 13.2028 6.87873 12.9135 7.13922 12.8186C7.19569 12.7983 7.25371 12.7887 7.31057 12.7887C7.51611 12.7887 7.70974 12.9151 7.78389 13.1191ZM13.2456 18.2411C13.9141 17.9976 14.2599 17.2561 14.0171 16.5876C14.0171 16.5876 14.0171 16.5876 14.0171 16.5868C13.7727 15.9191 13.0297 15.5733 12.3635 15.8169C12.04 15.9344 11.7814 16.1711 11.6358 16.4838C11.4902 16.7958 11.4748 17.1462 11.5924 17.4697C11.7104 17.7932 11.947 18.0521 12.2597 18.1981C12.4326 18.2788 12.6178 18.3195 12.8038 18.3195C12.9528 18.3195 13.1019 18.2934 13.2456 18.2411ZM13.2779 16.8573C13.3724 17.1178 13.2371 17.4067 12.9767 17.502C12.8514 17.5473 12.7154 17.5419 12.5925 17.485C12.4703 17.4282 12.3781 17.3275 12.332 17.2007C12.2859 17.0747 12.292 16.938 12.3489 16.8158C12.4057 16.6944 12.5064 16.6022 12.6324 16.5561C12.6893 16.5353 12.7469 16.5253 12.8045 16.5253C13.0097 16.5253 13.2033 16.6529 13.2779 16.8573Z"
                  fill="#F0F0FC"
                />
                <path
                  d="M8.06091 0.0437934C7.49437 -0.0978291 6.89637 0.106752 6.36921 0.618162C5.88925 1.09029 5.51942 1.76695 5.31483 2.53015C5.11813 3.30128 5.1103 4.07235 5.30701 4.70969C5.51154 5.41785 5.93646 5.88993 6.50295 6.03943C6.621 6.07088 6.73899 6.08664 6.85703 6.08664C7.31341 6.08664 7.76979 5.8663 8.18678 5.46506C8.66673 5.00081 9.03657 4.31627 9.24115 3.55302C9.6739 1.86924 9.15456 0.334917 8.06091 0.0437934ZM8.4779 3.35631C8.31265 3.98582 8.0137 4.53656 7.64386 4.89852C7.45503 5.07947 7.07737 5.37059 6.69966 5.27618C6.31412 5.17389 6.13317 4.74114 6.05445 4.48935C5.90495 3.98582 5.91283 3.35631 6.07808 2.72686C6.24333 2.09741 6.54228 1.5545 6.91999 1.18471C7.07737 1.02733 7.37637 0.791292 7.69895 0.791292C7.75403 0.791292 7.80911 0.791292 7.85632 0.806997C8.42282 0.956495 8.81623 2.03445 8.4779 3.35631Z"
                  fill="#F0F0FC"
                />
                <path
                  d="M7.57441 3.58337C7.29117 4.03975 7.11804 4.48825 7.01575 4.88166C7.38558 4.9682 7.70028 5.23574 7.84191 5.61341C7.92062 5.82587 7.9285 6.04615 7.88129 6.25074C8.17241 6.07766 8.5894 5.77866 8.93561 5.28295C9.1402 4.99966 9.26607 4.72429 9.35265 4.48825L7.57441 3.58337Z"
                  fill="#F7524B"
                />
              </g>
              <defs>
                <clipPath id="clip0_98_146">
                  <rect width={24} height={24} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </p>
          <div>
            <span className="available-coupon-details">
              test beta.elearnmarkets.in
            </span>
            <span>
              <a className="available-coupon-code">
                elm321
                <span className="copy_icon" style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 488.3 488.3"
                  >
                    <g>
                      <g>
                        <path
                          d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124 C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124 c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"
                          fill="currentColor"
                        />
                        <path
                          d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227 c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6 V38.6C439.65,17.3,422.35,0,401.05,0z"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </span>
              </a>
            </span>
          </div>
          <p className="view_offer_btn">
            <a
              target="_blank"
              href="https://beta.elearnmarkets.in/cartlive#"
              style={{ cursor: "pointer" }}
            >
              View Offer{" "}
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default MyOffers