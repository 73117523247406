import React from 'react'

export default function HostCourse() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2969 1.50632H0.703125C0.314812 1.50632 0 1.82113 0 2.20944V18.025C0 18.4133 0.314812 18.7281 0.703125 18.7281H11.2969V21.0875H6.35156C5.96325 21.0875 5.64844 21.4023 5.64844 21.7906C5.64844 22.1789 5.96325 22.4938 6.35156 22.4938H17.6484C18.0368 22.4938 18.3516 22.1789 18.3516 21.7906C18.3516 21.4023 18.0368 21.0875 17.6484 21.0875H12.7031V18.7281H23.2969C23.6852 18.7281 24 18.4133 24 18.025V2.20944C24 1.82113 23.6852 1.50632 23.2969 1.50632ZM22.5938 17.3219H1.40625V15.7156H22.5938V17.3219ZM22.5938 14.3094H1.40625V2.91257H22.5938V14.3094Z" fill="currentColor"/>
            <path d="M6.49154 7.76685L9.03741 8.67609V12C9.03741 12.3884 9.35223 12.7032 9.74054 12.7032H14.2593C14.6476 12.7032 14.9624 12.3884 14.9624 12V8.67609L16.5687 8.10243V10.0231C16.5687 10.4114 16.8835 10.7262 17.2718 10.7262C17.6601 10.7262 17.9749 10.4114 17.9749 10.0231V7.1047C17.9749 6.80756 17.7881 6.54248 17.5083 6.44254L12.2364 4.55976C12.0835 4.5051 11.9163 4.5051 11.7634 4.55976L6.49154 6.44254C6.2117 6.54248 6.0249 6.80756 6.0249 7.1047C6.0249 7.40184 6.21175 7.66696 6.49154 7.76685ZM13.5562 11.2969H10.4437V9.17835L11.7634 9.64973C11.9164 9.70438 12.0834 9.70438 12.2364 9.64973L13.5562 9.17835V11.2969ZM11.9999 5.96854L15.1812 7.1047L11.9999 8.24095L8.8186 7.10474L11.9999 5.96854Z" fill="currentColor"/>
        </svg>


    )
}
