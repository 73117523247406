import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../asssets/styles/host_course.css";
import {
  MyCertificateIcon,
  MyCourseIcon,
  MyWebinarIcon,
  MyOfferIcon,
  ElmSchoolIcon,
  MyProfileIcon,
  SupportIcon,
  WorkshopIcon,
  AuthorDashboard,
  CourseDiscussion,
  HostCourse,
  HostWebinar,
  PromotionLink,
  AffiliateIcon,
} from "../../asssets/icons";
import UploadProfilePhoto from "./UploadProfilePhoto";
import { UserType } from "../../types/UserTypes";
import { MenuType } from "../../types/CommonTypes";


const LeftPanel = ({
  isClickedMobileMenu,
  setIsClickedMobileMenu,
  userDetails,
}: {
  isClickedMobileMenu: boolean;
  setIsClickedMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  userDetails: UserType;
}) => {
  const [tabId, setTabId] = useState("1");
  let LeftPanelType: {
    [key: string]: MenuType[];
  } = {
    "1": [
      { name: "MY COURSE", icon: <MyCourseIcon />, link: "/my-courses" },
      { name: "MY WEBINARS", icon: <MyWebinarIcon />, link: "/my-webinars" },
      {
        name: "MY CERTIFICATES",
        icon: <MyCertificateIcon />,
        link: "/my-certificates",
      },
      { name: "ELM SCHOOL", icon: <ElmSchoolIcon />, link: "/elm-school" },
      { name: "MY EVENTS", icon: <WorkshopIcon />, link: "/my-events" },
      { name: "MY OFFERS", icon: <MyOfferIcon />, link: "/my-offers" },
      { name: "SUPPORT", icon: <SupportIcon />, link: "/support" },
      { name: "PROFILE", icon: <MyProfileIcon />, link: "/profile" },
    ],
    "2": [
      { name: "DASHBOARD", icon: <AuthorDashboard />, link: "/dashboard" },
      { name: "PROMO LINK", icon: <PromotionLink />, link: "/author-link" },
      { name: "COURSES", icon: <HostCourse />, link: "/host-courses" },
      { name: "WEBINARS", icon: <HostWebinar />, link: "/host-webinars" },
      {
        name: "COURSE DISCUSSION",
        icon: <CourseDiscussion />,
        link: "/discussion",
      },
      {
        name: "AUTHOR PROFILE",
        icon: <MyProfileIcon />,
        link: "/author-profile",
      },
    ],
  };
  return (
    <>
      <div className="col-lg-3">
        <div
          onClick={() => setIsClickedMobileMenu(false)}
          className={
            isClickedMobileMenu
              ? "left-menu-overley show-overley"
              : "left-menu-overley"
          }
        />
        <div
          className={
            isClickedMobileMenu
              ? "left-menu-mobile open-left-menu"
              : "left-menu-mobile"
          }
        >
          <div className="left-panel-my-account">
                <UploadProfilePhoto imageLink={userDetails.photo} />
                <div className="user-name">
                  <span>
                    {userDetails.firstName + " " + userDetails.lastName}
                  </span>
                  {userDetails.isAuthor === 1 && (
                    <p className="author-and-student-btn">
                      <Link
                        to={"/my-courses"}
                        onClick={() => setTabId("1")}
                        className={tabId === "1" ? "active" : ""}
                      >
                        View as Student
                      </Link>
                      <Link
                        to={"/dashboard"}
                        onClick={() => setTabId("2")}
                        className={tabId === "2" ? "active" : ""}
                      >
                        View as Trainer
                      </Link>
                    </p>
                  )}
                </div>
            <ul className="side-menu-account">
              {LeftPanelType[tabId].map((menu, ind: number) => (
                <div key={ind}>
                  <li>
                    <NavLink
                      to={menu.link}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      {menu.icon}
                      {menu.name}
                    </NavLink>
                  </li>
                  { userDetails.isAffiliate === 1 && ind === 5 && (
                    <li>
                      <NavLink
                        to={"/affiliates"}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <AffiliateIcon />
                        Affiliates
                      </NavLink>
                    </li>
                  )}
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
