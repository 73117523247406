import React from 'react'

export default function PromotionLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.625 10.5C23.625 8.68916 22.3346 7.17403 20.625 6.8256V4.13635C20.625 3.43066 20.3513 2.76819 19.8541 2.27087C19.3572 1.77393 18.6949 1.5 17.9888 1.5C17.651 1.5 17.3106 1.56592 17.009 1.6886L10.784 4.17847L11.341 5.57153L17.5704 3.07983C17.9669 2.91797 18.4781 3.01575 18.7934 3.33142C19.0073 3.54529 19.125 3.8313 19.125 4.13635V6.75V14.25V15.7386C19.125 16.3652 18.6152 16.875 17.9888 16.875C17.8433 16.875 17.6984 16.8475 17.566 16.7933L9 13.3671V5.25H4.61627C3.61542 5.25 2.67462 5.63965 1.96692 6.34753L1.78729 6.52698C1.33539 6.97888 0.98291 7.50476 0.738831 8.0918C0.497498 8.67847 0.375 9.29956 0.375 9.9375C0.375 11.2258 0.876526 12.4369 1.78711 13.3477L2.6814 14.2421L4.02209 21.2805C4.15668 21.9873 4.77631 22.5 5.49554 22.5H8.84363C9.29224 22.5 9.71356 22.3019 9.99939 21.9562C10.2854 21.6108 10.4011 21.16 10.3173 20.7195L9.6297 17.1097L8.15607 17.3903L8.84363 21H5.49573L4.28139 14.625H8.10516L17.0046 18.1846C17.3106 18.3091 17.651 18.375 17.9888 18.375C19.4425 18.375 20.625 17.1925 20.625 15.7386V14.1744C22.3346 13.826 23.625 12.3108 23.625 10.5ZM7.5 13.125H3.68573L2.84784 12.2871C2.22052 11.6598 1.875 10.8252 1.875 9.9375C1.875 9.49622 1.95941 9.06738 2.12512 8.66528C2.2923 8.26282 2.53546 7.90027 2.84821 7.58752L3.02783 7.40771C3.45209 6.98364 4.01624 6.75 4.61627 6.75H7.5V13.125ZM20.625 12.6215V8.37854C21.498 8.68799 22.125 9.52222 22.125 10.5C22.125 11.4778 21.498 12.312 20.625 12.6215Z" fill="currentColor"/>
        </svg>

    )
}
