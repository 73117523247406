import { Link } from "react-router-dom";

const ErrorModal = () => {
  return (
    <>
      <div
        className={`modal fade show`}
        style={{ display: "block" }}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Session Expired</h4>
            </div>
            <div className="modal-body">Please log in again</div>
            <div className="modal-footer">
              <Link
                to={process.env.REACT_APP_NEXT_HOME_URL + "login"}
                className="btn elm-btn btn-primary"
              >
                {" "}
                Go to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ErrorModal;
