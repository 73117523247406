import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";
//MyCertificate

import Layout from "../components/common/Layout";
import Report from "../pages/Report";
import MyCertificate from "../pages/MyCertificate";
import ELMSchool from "../pages/ELMSchool";
import MyOffers from "../pages/MyOffers";
import Support from "../pages/Support";
import Profile from "../pages/Profile";
import Affiliates from "../pages/Affiliates";
import MyEvents from "../pages/MyEvents";
// const Layout = lazy(()=>import('../components/common/Layout'))
const MyCourses = lazy(() => import("../pages/MyCourses"));
const MyWebinars = lazy(() => import("../pages/MyWebinars"));
const Learn = lazy(() => import("../pages/Learn"));
const OtpScreen = lazy(() => import("../pages/OtpScreen"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route index={true} element={<Navigate replace to={"my-courses"} />} />
        <Route
          path="my-courses"
          element={
              <MyCourses />
          }
        />
        <Route
          path="my-webinars"
          element={
            <Suspense fallback={<></>}>
              <MyWebinars />
            </Suspense>
          }
        />
        <Route
          path="dashboard"
          element={
            <Suspense fallback={<></>}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="report"
          element={
            <Suspense fallback={<></>}>
              <Report />
            </Suspense>
          }
        />
        <Route
          path="my-certificates"
          element={
            <Suspense fallback={<></>}>
              <MyCertificate />
            </Suspense>
          }
        />
        <Route
          path="elm-school"
          element={
            <Suspense fallback={<></>}>
              <ELMSchool />
            </Suspense>
          }
        />
        <Route
          path="my-events"
          element={
            <Suspense fallback={<></>}>
              <MyEvents />
            </Suspense>
          }
        />
        <Route
          path="my-offers"
          element={
            <Suspense fallback={<></>}>
              <MyOffers />
            </Suspense>
          }
        />
        <Route
          path="affiliates"
          element={
            <Suspense fallback={<></>}>
              <Affiliates />
            </Suspense>
          }
        />
        <Route
          path="support"
          element={
            <Suspense fallback={<></>}>
              <Support />
            </Suspense>
          }
        />
        <Route
          path="profile"
          element={
            <Suspense fallback={<></>}>
              <Profile />
            </Suspense>
          }
        />
      </Route>
      <Route path="/learn">
        <Route
          path=":slug"
          element={
            <Suspense fallback={<></>}>
              <Learn />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/otp-screen"
        element={
          <Suspense fallback={<></>}>
            <OtpScreen />
          </Suspense>
        }
      />
    </Route>
  )
);

const CustomRoutes = () => {
  return <RouterProvider router={router} />;
};

export default CustomRoutes;
