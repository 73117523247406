import React, { Suspense, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import LeftPanel from "./LeftPanel";
// import { userType } from '../../data/DataForTest'
import RightPanel from "./RightPanel";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHook";
import { useLocation } from "react-router-dom";
import ErrorModal from "./ErrorModal";
import { fetchUserData } from "../../services/userSlice";
import ErrorElement from "./ErrorElement";
import ElmLoader from "../../asssets/icons/ElmLoader";

const Layout = () => {
  //const navigate = useNavigate()
  const location = useLocation();

  const errorData = useAppSelector((state) => state.errorSlice);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  useEffect(() => {
    if (errorData.errorCode === 401) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [errorData.errorCode]);

  const generateHeader = () => {
    location.pathname =
      location.pathname === "/profile"
        ? location.pathname + "-settings"
        : location.pathname;
    const pathSegment = location.pathname.split("/")[1];
    const headerParts = pathSegment ? pathSegment.toUpperCase().split("-") : [];

    let headerName;
    if (headerParts.length > 1) {
      headerName = (
        <>
          {" "}
          {headerParts[0]} <span>{headerParts.slice(1).join(" ")}</span>
        </>
      );
    } else {
      headerName = (
        <>
          <span>{headerParts[0]}</span>
        </>
      );
    }
    return headerName;
  };
  const [isClickedMobileMenu, setIsClickedMobileMenu] =
    useState<boolean>(false);

  const userData = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);
  console.log("userData redux", userData);

  return (
    <>
      {userData.error === "" && !userData.loading && userData.userDetails && (
        <div>
          <Header setIsClickedMobileMenu={setIsClickedMobileMenu} />
          <div className="container-fluid padding-on-top-my-account">
            <div className="container">
              <div
                className="row justify-content-md-center"
                style={{ minHeight: 500 }}
              >
                <LeftPanel
                  isClickedMobileMenu={isClickedMobileMenu}
                  setIsClickedMobileMenu={setIsClickedMobileMenu}
                  userDetails={userData.userDetails}
                />

                <div className="col-lg-9">
                  <p className="top-header-my-account">{generateHeader()}</p>

                  <Suspense fallback={<></>}>
                    <RightPanel />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {showErrorModal && <ErrorModal />}
        </div>
      )}

      {userData.error && Number(userData.errorCode) === 401 && <ErrorModal />}
      {userData.error && Number(userData.errorCode) !== 401 && <ErrorElement />}
      {userData.loading && (
        <div className="container-fluid padding-on-top-my-account">
          <div className="container">
            <div
              style={{ minHeight: 500, display: "flex", justifyContent : "center", alignItems : "center" }}
            >
              <ElmLoader height={100} width={100} color="#FF8024" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
