import React from 'react'

export default function AuthorDashboard() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8906 0.796875H2.10938C0.946266 0.796875 0 1.74314 0 2.90625V16.5938C0 17.7569 0.946266 18.7031 2.10938 18.7031H8.91998L8.40436 21.7969H7.5C7.11169 21.7969 6.79688 22.1117 6.79688 22.5C6.79688 22.8883 7.11169 23.2031 7.5 23.2031H16.5C16.8883 23.2031 17.2031 22.8883 17.2031 22.5C17.2031 22.1117 16.8883 21.7969 16.5 21.7969H15.5956L15.08 18.7031H21.8906C23.0537 18.7031 24 17.7569 24 16.5938V2.90625C24 1.74314 23.0537 0.796875 21.8906 0.796875ZM14.17 21.7969H9.83002L10.3456 18.7031H13.6544L14.17 21.7969ZM22.5938 16.5938C22.5938 16.9815 22.2783 17.2969 21.8906 17.2969C21.389 17.2969 2.6978 17.2969 2.10938 17.2969C1.72167 17.2969 1.40625 16.9815 1.40625 16.5938V2.90625C1.40625 2.51855 1.72167 2.20312 2.10938 2.20312H11.2969V3C11.2969 3.38831 11.6117 3.70312 12 3.70312C12.3883 3.70312 12.7031 3.38831 12.7031 3V2.20312H21.8906C22.2783 2.20312 22.5938 2.51855 22.5938 2.90625V16.5938Z" fill="currentColor"/>
            <path d="M9.75 9.79688C9.36169 9.79688 9.04688 10.1117 9.04688 10.5V12.7969H7.45312V8.25C7.45312 7.86169 7.13831 7.54688 6.75 7.54688C6.36169 7.54688 6.04688 7.86169 6.04688 8.25V12.7969H4.45312V6C4.45312 5.61169 4.13831 5.29688 3.75 5.29688C3.36169 5.29688 3.04688 5.61169 3.04688 6V13.5C3.04688 13.8883 3.36169 14.2031 3.75 14.2031H9.75C10.1383 14.2031 10.4531 13.8883 10.4531 13.5V10.5C10.4531 10.1117 10.1383 9.79688 9.75 9.79688Z" fill="currentColor"/>
            <path d="M16.875 5.67188C14.6263 5.67188 12.7969 7.50131 12.7969 9.75C12.7969 10.8575 13.2409 11.8632 13.96 12.5988C13.9923 12.6357 13.9907 12.6339 14.0261 12.665C14.7618 13.3841 15.7674 13.8281 16.875 13.8281C19.1237 13.8281 20.9531 11.9987 20.9531 9.75C20.9531 7.50131 19.1237 5.67188 16.875 5.67188ZM19.4523 9.04688H17.5781V7.17267C18.4871 7.42092 19.2041 8.13792 19.4523 9.04688ZM16.1719 7.17267V9.45877L14.556 11.0746C13.6867 9.5587 14.4944 7.63087 16.1719 7.17267ZM15.5504 12.069L17.1662 10.4531H19.4523C18.9934 12.1331 17.0642 12.937 15.5504 12.069Z" fill="currentColor"/>
        </svg>

    )
}
