import React from 'react'

function Support() {
  return (
    <div>
  <div className="row">
    <div className="col-lg-12">
      <div className="section-header">
        <p>
          REPORT <span className="blue-text">an Issue</span>
        </p>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-12">
      <form  className="support-index-page-form">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <select
                required
                name="postComment[category]"
                //placeholder="Type*"
                id="exampleForm.ControlSelect1"
                className="form-control"
              >
                <option value="">Type*</option>
                <option value="webinar_query_"> Webinar Query</option>
                <option value="course_query"> Course Query </option>
                <option value="general_query"> General Query </option>
                <option value="payment_related_query">
                  {" "}
                  Payment Related Query{" "}
                </option>
                <option value="content_related_query">
                  {" "}
                  Content Related Query{" "}
                </option>
              </select>
              <div className="invalid-feedback">Please choose a type.</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <input
                name="postComment[title]"
                placeholder="Subject*"
                required
                type="text"
                id="exampleForm.ControlInput1"
                className="form-control"
              />
              <div className="invalid-feedback">Please Enter Subject.</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <textarea
                rows={4}
                name="postComment[message]"
                placeholder="Message*"
                required
                id="exampleForm.ControlTextarea1"
                className="form-control"
                defaultValue={""}
              />
              <div className="invalid-feedback">Please Enter Message.</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="choose-fli-btn-style form-file">
                <label
                  htmlFor="exampleFormControlFile1"
                  className="form-file-label"
                >
                  Attach File (.jpg,.png)
                </label>
                <input
                  name="file"
                  placeholder="Attach File .jpg,.png*"
                  accept="image/*"
                  id="exampleFormControlFile1"
                  type="file"
                  className="form-control-file"
                />
              </div>
            </div>
            <span className="text-danger" />
          </div>
          <div className="col-lg-12 text-right">
            <button className="report-submit-btn">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr className="react-hr-dashed-style" />
  <div className="row">
    <div className="col-lg-12">
      <div className="section-header">
        <p>
          TICKETS <span className="blue-text">History</span>
        </p>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-lg-12">
      <table className="tickets-hitory-table table-responsive">
        <thead>
          <tr>
            <th>Date</th>
            <th>Ticket Code</th>
            <th>Subject</th>
            <th>Status</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5}>You have no support request</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

  )
}

export default Support