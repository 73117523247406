import React from "react";

function MyCertificateIcon() {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1892 1.04895H0.810828C0.363071 1.04895 0 1.41202 0 1.85978V18.6435C0 19.0913 0.363071 19.4544 0.810828 19.4544H13.3508V22.1402C13.3508 22.4212 13.4963 22.6822 13.7354 22.83C13.9744 22.9776 14.2728 22.9912 14.5241 22.8654L16.4926 21.8812L18.4611 22.8654C18.5755 22.9226 18.6996 22.951 18.8237 22.951C18.9719 22.951 19.1198 22.9104 19.2499 22.83C19.489 22.6822 19.6345 22.4212 19.6345 22.1402V19.4544H23.1892C23.6369 19.4544 24 19.0913 24 18.6435V1.85978C24 1.41202 23.6369 1.04895 23.1892 1.04895ZM16.4926 15.8407C14.7897 15.8407 13.4041 14.4552 13.4041 12.7523C13.4041 11.0494 14.7897 9.66382 16.4926 9.66382C18.1956 9.66382 19.581 11.0494 19.581 12.7523C19.581 14.4553 18.1956 15.8407 16.4926 15.8407ZM16.8552 20.2494C16.7411 20.1924 16.6169 20.1639 16.4926 20.1639C16.3683 20.1639 16.2441 20.1924 16.13 20.2494L14.9723 20.8283V17.2092C15.4496 17.3724 15.9606 17.4624 16.4925 17.4624C17.0245 17.4624 17.5355 17.3724 18.0128 17.2092V20.8283L16.8552 20.2494ZM22.3783 17.8327H19.6345V16.2575C20.5963 15.3946 21.2027 14.1431 21.2027 12.7522C21.2027 10.1551 19.0897 8.04216 16.4926 8.04216C13.8955 8.04216 11.7825 10.1551 11.7825 12.7522C11.7825 14.1431 12.3889 15.3946 13.3507 16.2575V17.8327H1.62166V2.67061H22.3784V17.8327H22.3783Z"
        fill="currentColor"
      />
      <path
        d="M3.6077 6.86647H13.3982C13.846 6.86647 14.2091 6.5034 14.2091 6.05564C14.2091 5.60788 13.846 5.24481 13.3982 5.24481H3.6077C3.15995 5.24481 2.79688 5.60788 2.79688 6.05564C2.79688 6.5034 3.15995 6.86647 3.6077 6.86647Z"
        fill="currentColor"
      />
      <path
        d="M3.6077 11.0625H9.20229C9.65005 11.0625 10.0131 10.6994 10.0131 10.2516C10.0131 9.80387 9.65005 9.4408 9.20229 9.4408H3.6077C3.15995 9.4408 2.79688 9.80387 2.79688 10.2516C2.79696 10.6995 3.15995 11.0625 3.6077 11.0625Z"
        fill="currentColor"
      />
      <path
        d="M3.6077 15.2584H7.80366C8.25142 15.2584 8.61449 14.8954 8.61449 14.4476C8.61449 13.9998 8.25142 13.6368 7.80366 13.6368H3.6077C3.15995 13.6368 2.79688 13.9998 2.79688 14.4476C2.79688 14.8954 3.15995 15.2584 3.6077 15.2584Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default MyCertificateIcon;
