import React from "react";
import demoCertificate from "../../asssets/images/ELM_Certificate.jpg";
import { Link } from "react-router-dom";

const ElmCertificate = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img src={demoCertificate} />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p className="certificate-for-course">
                    Derivative Analytics Made Easy(test)
                  </p>
                </div>
              </div>
              <div className="certificate-progressbar">
                <div className="certificate-progress-bar progress">
                  <div
                    role="progressbar"
                    className="progress-bar"
                    aria-valuenow={11}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "11%" }}
                  />
                </div>
                <span className="certificate-progress">11%</span>
              </div>
              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-12">
                    <p className="progress-bar-text">
                      Complete the course to get your certificate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  src={demoCertificate}
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p className="certificate-for-course">
                    Multi-Asset Trading with SD Rainbow Wave
                  </p>
                </div>
              </div>

              <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
                <div className="row">
                  <div className="col-6 text-start">
                    <Link
                      to={""}
                      className="elm-certificate-download-btn"
                      //onClick={()=>downloadCertificate(props.certificateData.pdf_link, props.certificateData.name_in_english)}
                    >
                      <i
                        className={
                          // downloadStart
                          //</Link> ? `fa fa-circle-o-notch fa-spin`
                          //: `fa fa-download`
                          `fa fa-download`
                        }
                        aria-hidden="true"
                      ></i>{" "}
                      Download
                    </Link>
                  </div>
                  <div className="col-6 text-end">
                    <Link
                      to={""}
                      className="elm-certificate-download-btn"
                      target="_blank"
                    >
                      <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElmCertificate;
