import React from 'react'

function MiniNav() {
  return (
   <nav className="certificate-top-tab nav nav-tabs" role="tablist">
  <a
    id="affiliate-tabs-tab-affR"
    href="#"
    role="tab"
    data-rb-event-key="affR"
    aria-controls="affiliate-tabs-tabpane-affR"
    aria-selected="true"
    className="nav-item nav-link active"
  >
    Registrations
  </a>
  <a
    id="affiliate-tabs-tab-affC"
    href="#"
    role="tab"
    data-rb-event-key="affC"
    aria-controls="affiliate-tabs-tabpane-affC"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    Earning
  </a>
  <a
    id="affiliate-tabs-tab-affL"
    href="#"
    role="tab"
    data-rb-event-key="affL"
    aria-controls="affiliate-tabs-tabpane-affL"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    My Links
  </a>
  <a
    id="affiliate-tabs-tab-bank_details"
    href="#"
    role="tab"
    data-rb-event-key="bank_details"
    aria-controls="affiliate-tabs-tabpane-bank_details"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    Bank Details
  </a>
  <a
    id="affiliate-tabs-tab-video_guide"
    href="#"
    role="tab"
    data-rb-event-key="video_guide"
    aria-controls="affiliate-tabs-tabpane-video_guide"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    Tutorial
  </a>
  <a
    id="affiliate-tabs-tab-commission_details"
    href="#"
    role="tab"
    data-rb-event-key="commission_details"
    aria-controls="affiliate-tabs-tabpane-commission_details"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    Commission Details
  </a>
  <a
    id="affiliate-tabs-tab-genuine_registration"
    href="#"
    role="tab"
    data-rb-event-key="genuine_registration"
    aria-controls="affiliate-tabs-tabpane-genuine_registration"
    aria-selected="false"
    className="nav-item nav-link"
    tabIndex={-1}
  >
    Verified Report
  </a>
</nav>
  )
}

export default MiniNav