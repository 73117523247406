import React from 'react'
import { Link } from 'react-router-dom'

const AllInOneCertificate = () => {
  return (
    <div className="row">
  <div className="col-lg-12">
    <div className="row">
      <div className="col-lg-6">
        <div className="card custom-learning-card">
          <div className="learning-card-left-img" />
          <div className="row">
            <div className="col-12 ">
              <p className="certificate-for-course">
                Certification in Online Research, Trading &amp; Advisory
              </p>
            </div>
          </div>
          <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
            <div className="row">
              <div className="col-6 pt-2 pb-1 text-start">
                <Link
                  className="elm-certificate-download-btn"
                  to={''}
                  data-tip="download"
                  //currentitem="false"
                >
                  <i className="fa fa-download" aria-hidden="true" /> Download
                </Link>
              </div>
              <div className="col-6 pt-2 pb-1 text-end">
                <Link
                  className="elm-certificate-download-btn"
                  to="http://dev.com/share/YWxsaW5vbmUtMTIy/4"
                  target="_blank"
                  data-tip="share"
                  //currentitem="false"
                >
                  <i className="fa fa-share-alt" aria-hidden="true" /> Share
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card custom-learning-card">
          <div className="learning-card-left-img">
            <img
              src="http://dev.com/images/allinone_certificate_demo.jpg"
              className="img-fluid"
              loading="lazy"
            />
          </div>
          <div className="row">
            <div className="col-12 ">
              <p className="certificate-for-course">
                Certification in Online Research, Trading &amp; Advisory
              </p>
            </div>
          </div>
          <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
            <div className="row">
              <div className="col-12 pt-2 pb-1">
                <p className="progress-bar-text">Waiting for Approval</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card custom-learning-card">
          <div className="learning-card-left-img">
            <img
              src="http://dev.com/images/allinone_certificate_demo.jpg"
              className="img-fluid"
              loading="lazy"
            />
          </div>
          <div className="row">
            <div className="col-12 ">
              <p className="certificate-for-course">
                Certification in Online Research, Trading &amp; Advisory
              </p>
            </div>
          </div>
          <div className="learning-card-right-containt pb-0 pt-0 mt-3 mb-2">
            <div className="row">
              <div className="col-12 pt-2 pb-1">
                <p className="progress-bar-text">Waiting for Approval</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default AllInOneCertificate