

function SkeletonCourseLoader() {
  const loaderElements = [1, 2, 3, 4, 5, 6];

  return (
    <div className="row">
    {loaderElements.map((ind) => (
      <div className="col-lg-4 mb-5" key={ind}>
      <div className="card custom-learning-card" aria-hidden={true}>
        <svg
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="123"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div className="card-body">
        <center>
          <p className="card-text placeholder-glow">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#868e96"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
              aria-label="placeholder-glow"
              style={{marginRight: "10px"}}
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <span className="placeholder col-9"></span>
            <span className="placeholder col-12 placeholder-sm"></span>
            <span className="placeholder col-6 placeholder-sm " style={{width: "50%"}}></span>
            
          </p>
          </center>
        </div>
      </div>
    </div>
    ))}
        
    </div>
  );
}

export default SkeletonCourseLoader;
