import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types/CommonTypes'

const initialState: ErrorType={
  isError : false,
  errorCode : 0
}

export const errorSlice=createSlice({
  name:'error',
  initialState,
  reducers:{
    setError:(state, action: PayloadAction<ErrorType>)=>{
      state.isError = true
      state.errorCode = action.payload.errorCode
    },

  }
})


export const {setError} = errorSlice.actions
export default errorSlice.reducer