import React, { useEffect, useState } from 'react'
import SkeletonCourseLoader from '../components/common/SkeletonLoaders/SkeletonCourseLoader';


function ELMSchool() {  
  const [loadingSchool, setLoadingSchool] = useState(false)

  useEffect(()=>{
    setLoadingSchool(true)
    setTimeout(() => {setLoadingSchool(false)}, 5000)

  }, [])

return (
    <div className="col-lg-12 mb-4">
      {!loadingSchool &&
        <div className="row">
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/guide-to-mutual-funds/what-kind-of-expenses-can-be-charged-to-mutual-fund-scheme-and-up-to-what-extent"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/qJafEtsNwm.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                  {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Continue Learning
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={3}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "3%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  97% more to complete"
                      >
                        3%
                      </span>
                      <span className="dayes-left-expired">1 out of 34</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">Guide to Mutual Funds</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/basics-of-stock-investing/fundamental-analysis"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/iVhpNxBmMt.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Continue Learning
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={11}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "11%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  89% more to complete"
                      >
                        11%
                      </span>
                      <span className="dayes-left-expired">1 out of 9</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">
                    Basics of Stock Investing
                  </span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/initial-public-offerings-ipo/introduction-2"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/YwAxMxrkpX.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Completed
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={100}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  0% more to complete"
                      >
                        100%
                      </span>
                      <span className="dayes-left-expired">13 out of 13</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">
                    Initial Public Offerings (IPO)
                  </span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/how-to-invest-in-shares-with-only-rs-5000/introduction"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/UbODJnjZSv.jpg"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Completed
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={100}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  0% more to complete"
                      >
                        100%
                      </span>
                      <span className="dayes-left-expired">5 out of 5</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">
                    How to invest in shares with only Rs. 5000?
                  </span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/corporate-action/what-is-a-corporate-action"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/AMiZjymHNl.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Continue Learning
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "50%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  50% more to complete"
                      >
                        50%
                      </span>
                      <span className="dayes-left-expired">6 out of 12</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">Corporate Action</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/financial-planning/budgeting-and-setting-financial-goals"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/ZmCibLyzdz.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Continue Learning
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={11}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "11%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  89% more to complete"
                      >
                        11%
                      </span>
                      <span className="dayes-left-expired">4 out of 35</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">Financial Planning</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/savings-and-investment/post-office-recurring-deposit"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/GVJokgqYkc.jpg"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Continue Learning
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={9}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "9%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  91% more to complete"
                      >
                        9%
                      </span>
                      <span className="dayes-left-expired">3 out of 32</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">Savings and Investment</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a
            target="_blank"
            href="http://next.dev.com:3000/school/units/importance-of-investments/saving-or-investing"
          >
            <div className="card custom-learning-card">
              <div className="learning-card-left-img">
                <img
                  alt=""
                  src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/GkPFRMDxrh.png"
                  className="img-fluid"
                />
              </div>
              <div className="learning-card-right-containt">
                <img
                  alt=""
                  src="/static/media/Ellipse.8f258975.png"
                  className="ellipse"
                />
                <div className="row">
                  <div className="col-2 pl-0">
                    {/* <FaCirclePlay
                  style={{ color: "#ee9949", fontSize: "30px", marginTop: "14px" }}
                /> */}
                  </div>
                  <div className="col-10 pr-0">
                    <small style={{ fontSize: 9, color: "rgb(156, 156, 156)" }}>
                      Completed
                    </small>
                    <div className="custom-progress-bar-course">
                      <div className="progress">
                        <div
                          role="progressbar"
                          className="progress-bar bg-success"
                          aria-valuenow={100}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <span
                        className="percentage-of-complete"
                        data-tip="You have  0% more to complete"
                      >
                        100%
                      </span>
                      <span className="dayes-left-expired">13 out of 13</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-1">
                  <span className="learning-name mb-0">
                    Importance of Investments
                  </span>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      }
      {loadingSchool && <SkeletonCourseLoader/>}
  
</div>

);
};


export default ELMSchool