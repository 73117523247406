import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ElmCertificate from '../components/myCertificate/ElmCertificate'
import NseCertificate from '../components/myCertificate/NseCertificate'
import NcdexCertificate from '../components/myCertificate/NcdexCertificate'
import AllInOneCertificate from '../components/myCertificate/AllInOneCertificate'
function MyCertificate() {
  const [key, setKey] = useState('elm')
  const changeTab = (e : React.MouseEvent<HTMLAnchorElement, MouseEvent>, keyString : string) => {
   setKey(keyString)
  }
  return (
   <>
  <nav className="certificate-top-tab nav nav-tabs" role="tablist">
    <Link
      to={''}
      role="tab"
      className={`nav-item nav-link ${key === 'elm' ? 'active' : ''}`}
      aria-selected = {key === "elm" ? true : false}
      onClick = {(e) => changeTab(e, 'elm')}
    >
      Courses By Elearnmarkets
    </Link>
    <Link
      to={''}
      role="tab"
      aria-selected={key === "nse" ? true : false}
      className={`nav-item nav-link ${key === 'nse' ? 'active' : ''}`}
      
      onClick = {(e) => changeTab(e, 'nse')}
    >
      Other Certified Courses
    </Link>
    <Link
      to={''}
      role="tab"
      aria-selected={key === "ncdex" ? true : false}
      className={`nav-item nav-link ${key === 'ncdex' ? 'active' : ''}`}
      
      onClick = {(e) => changeTab(e, 'ncdex')}
    >
      NCDEX
    </Link>
    
    <Link
      to={''}
      role="tab"
      aria-selected={key === "allInOne" ? true : false}
      className={`nav-item nav-link ${key === 'allInOne' ? 'active' : ''}`}
      
      onClick = {(e) => changeTab(e, 'allInOne')}
    >
      All in one
    </Link>
    
  </nav>
  <div className="tab-content">
    <div
      role="tabpanel"
      aria-hidden={key === "elm" ? false : true}
      className={`fade tab-pane ${key === "elm" ? 'active show' : ''}`}
    >
      <ElmCertificate />
    </div>
    <div
      role="tabpanel"
      aria-hidden={key === "nse" ? false : true}
      className={`fade tab-pane ${key === "nse" ? 'active show' : ''}`}
    >
      <NseCertificate />
    </div>
    <div
      role="tabpanel"
      aria-hidden={key === "ncdex" ? false : true}
      className={`fade tab-pane ${key === "ncdex" ? 'active show' : ''}`}
    >
      <NcdexCertificate />
    </div>
    <div
      role="tabpanel"
      aria-hidden={key === "allInOne" ? false : true}
      className={`fade tab-pane ${key === "allInOne" ? 'active show' : ''}`}
    >
      <AllInOneCertificate />
    </div>
  </div>
</>
  )
}

export default MyCertificate