import React, { lazy, useState } from 'react'

function MyEvents() {
  const[workshopLoadingFirst, setWorkshopLoadingFirst] = useState(false)
  const [workshopData, setWorkshopData] = useState([{workshop : {w_pic : "", name : "test event", start_date : new Date(), end_date: new Date() }}])
  
  const getDateOfTime = (dt:any) => {
    let mdt = new Date(dt)
    return (mdt.toLocaleString('default', { month: 'short' })) +' '+ mdt.getDate() + ', '  + (mdt.getFullYear());
}
  return (
    <div className="col-lg-12 mb-4">
          {!workshopLoadingFirst && (
            <div className="row">

              {workshopData.map((data) => (
                <div className="col-lg-4">
                  
                  <div className="card custom-learning-card" style={{cursor: "pointer"}}>
                      <div className="learning-card-left-img">
                          {data.workshop.w_pic !== null ? <img  alt="" src={`${'http://dev.com' + '/site/assets/img/blank_image.png'}`}  className="img-fluid" loading='lazy' /> : 
                          <img  alt="" src={`${'http://dev.com' + '/site/assets/img/blank_image.png'}`} loading='lazy'  className="img-fluid" />}
                      </div>
                    <div className="learning-card-right-containt">
                 <p className="mb-0 mt-1">
                <span className="learning-name mb-0">
                  {data.workshop.name}
                </span>
              </p>
              <span className="webinar-date">
                  <i className="fa fa-calendar" aria-hidden="true"></i> 
                  {getDateOfTime(data.workshop.start_date)}  To {getDateOfTime(data.workshop.end_date)}
                </span>
                 </div>
                  
                  </div>
                </div>
              ))

              }

            </div>
          )}
        </div>
  )
}

export default MyEvents