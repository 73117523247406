import React from 'react'

const PowerIcon = () => {
  return (
    <svg fill="#fff" height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 500 500" enable-background="new 0 0 500 500" style={{marginTop : "5px"}} >
<g>
	<path d="M266.1,278.4c0.1,0,0.1,0,0.2-0.1c15.1-3.1,29.1-10.6,40.3-21.8c11.2-11.2,18.6-25.1,21.8-40.3c0-0.1,0-0.1,0.1-0.2
		c0.5-2.2,0.8-4.5,1-6.8c0-0.4,0.1-0.8,0.2-1.2c0.3-2.7,0.4-5.4,0.4-8.1c0-2.4-0.1-4.8-0.3-7.1c-0.1-0.8-0.2-1.5-0.3-2.3
		c-0.2-1.6-0.4-3.2-0.7-4.8c-0.2-0.8-0.3-1.7-0.5-2.5c-0.4-1.8-0.8-3.5-1.3-5.3c-0.2-0.8-0.5-1.7-0.7-2.5c-0.6-1.8-1.2-3.5-1.9-5.2
		c-0.3-0.8-0.7-1.6-1-2.4c-0.6-1.4-1.3-2.8-2-4.2c-0.3-0.7-0.7-1.3-1-2c-2.3-4.2-5-8.3-8.1-12c-0.2-0.2-0.4-0.4-0.6-0.7
		c-1.6-1.9-3.2-3.7-5-5.5c0,0,0,0,0,0c0,0,0,0,0,0c-7.2-7.2-15.7-13-25.4-17.1c-4.1-1.7-8.8,0.2-10.5,4.3c-1.7,4.1,0.2,8.8,4.3,10.5
		c7.8,3.3,14.6,8,20.4,13.7c1.4,1.4,2.7,2.9,4,4.4c0.1,0.2,0.3,0.3,0.4,0.5c2.5,3,4.6,6.3,6.5,9.6c0.3,0.5,0.5,1.1,0.8,1.6
		c0.6,1.1,1.1,2.3,1.6,3.4c0.3,0.6,0.6,1.3,0.8,1.9c0.6,1.4,1.1,2.8,1.5,4.2c0.2,0.6,0.4,1.3,0.6,1.9c0.4,1.4,0.8,2.8,1.1,4.2
		c0.1,0.7,0.3,1.3,0.4,2c0.2,1.3,0.4,2.5,0.6,3.8c0.1,0.6,0.2,1.2,0.2,1.8c0.2,1.9,0.3,3.8,0.3,5.7c0,2.2-0.1,4.4-0.3,6.5
		c0,0.3-0.1,0.6-0.1,0.9c-0.2,1.8-0.5,3.7-0.8,5.4c0,0.1,0,0.1,0,0.2c-5.2,24.8-24.8,44.4-49.6,49.6c-0.1,0-0.1,0-0.2,0
		c-1.8,0.4-3.6,0.6-5.4,0.8c-0.3,0-0.6,0.1-0.9,0.1c-2.1,0.2-4.3,0.3-6.5,0.3s-4.4-0.1-6.5-0.3c-0.3,0-0.6-0.1-0.9-0.1
		c-1.8-0.2-3.7-0.5-5.4-0.8c-0.1,0-0.1,0-0.2,0c-16.5-3.4-30.6-13.2-39.8-26.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.9-1.3-1.7-2.7-2.5-4
		c-0.3-0.5-0.6-0.9-0.8-1.4c-0.6-1.1-1.1-2.2-1.6-3.3c-0.4-0.8-0.8-1.6-1.1-2.5c-0.3-0.8-0.6-1.7-0.9-2.5c-0.4-1.2-0.9-2.3-1.2-3.5
		c-0.2-0.5-0.3-1-0.4-1.6c-0.4-1.5-0.8-3.1-1.1-4.6c0-0.1,0-0.2,0-0.3c-1.1-6-1.4-12-0.8-18c0-0.1,0-0.1,0-0.2
		c0.2-1.9,0.5-3.8,0.8-5.7c0-0.2,0.1-0.3,0.1-0.5c0.8-3.9,1.9-7.8,3.4-11.6c0-0.1,0.1-0.2,0.1-0.3c0.7-1.7,1.5-3.4,2.3-5
		c0.1-0.2,0.3-0.5,0.4-0.7c1.9-3.5,4.1-6.9,6.7-10.1c0.1-0.1,0.2-0.3,0.3-0.4c1.3-1.6,2.7-3.1,4.1-4.6c5.7-5.8,12.6-10.5,20.4-13.8
		c4.1-1.7,6-6.4,4.3-10.5c-1.7-4.1-6.4-6-10.5-4.3c-9.7,4.1-18.3,10-25.4,17.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1
		c-1.8,1.8-3.5,3.7-5.2,5.7c-0.1,0.2-0.3,0.3-0.4,0.5c-3.2,4-6,8.2-8.4,12.7c-0.2,0.3-0.3,0.6-0.5,0.9c-1.1,2-2,4.1-2.9,6.3
		c-0.1,0.1-0.1,0.3-0.2,0.4c-1.9,4.6-3.3,9.5-4.3,14.4c0,0.2-0.1,0.4-0.1,0.6c-0.4,2.3-0.8,4.7-1,7.1c0,0.1,0,0.2,0,0.3
		c-0.2,2.5-0.4,5-0.4,7.6c0,5.1,0.5,10.1,1.5,14.9c0,0.1,0,0.3,0.1,0.4c0.4,2,0.9,3.9,1.4,5.8c0.2,0.6,0.3,1.3,0.5,1.9
		c0.5,1.5,1,3,1.6,4.5c0.4,1,0.7,2.1,1.1,3.1c0.4,1.1,1,2.1,1.5,3.1c0.6,1.4,1.3,2.7,2,4.1c0.3,0.6,0.8,1.3,1.1,1.9
		c1,1.7,1.9,3.3,3,4.9c0.1,0.2,0.3,0.4,0.4,0.6c2.7,4,5.8,7.8,9.4,11.4c11.2,11.2,25.1,18.7,40.3,21.8c0.1,0,0.1,0,0.2,0
		c2.3,0.5,4.5,0.8,6.8,1c0.4,0,0.8,0.1,1.1,0.2c2.7,0.3,5.4,0.4,8.1,0.4s5.4-0.1,8.1-0.4c0.4,0,0.8-0.1,1.2-0.2
		C261.6,279.2,263.8,278.8,266.1,278.4z"/>
	<path d="M250,208c4.4,0,8-3.6,8-8v-72c0-4.4-3.6-8-8-8s-8,3.6-8,8v72C242,204.4,245.6,208,250,208z"/>
</g>
</svg>
  )
}

export default PowerIcon