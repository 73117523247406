import React from 'react'

const BarIcon = () => {
  return (
    <svg fill="#ee9949" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 455 455">
<g>
   <rect y="312.5" width="455" height="30"/>
   <rect y="212.5" width="455" height="30"/>
   <rect y="112.5" width="455" height="30"/>
</g>
</svg>
  )
}

export default BarIcon